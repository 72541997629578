$(document).on('turbo:load', function() {
	if ($("#user_password") && $("#user_password_confirmation")) {
		$("#new_user").validate({
			//error place
			errorPlacement: function (error, element) {
				if (element[0].id == "user_password" || element[0].id == "user_password_confirmation") {
					error.insertAfter(element.parent());
				} 
				else {
					error.insertAfter(element);
				}
			},
		  //adding rule
			rules: {
				// username is required with max of 20 and min of 6
				"user[firstname]":{
				  required: true,
	        minlength: 1,
	        maxlength: 16
				},		
	      "user[lastname]":{
				  required: true,
	        minlength: 1,
	        maxlength: 16
				},
				"user[email]":{
				  required: true,
	        email: true
				},
				"user[password]":{
				  required: true,	
				  minlength: 6
				},	
				"user[password_confirmation]":{
				  required: true,	
				  minlength: 6,
				  equalTo: "#user_password"
				},	
			}, 
			// error messages
			messages: {
				"user[firstname]":{
		  		required: I18n.t("validation.user.firstname.blank"),
          minlength: I18n.t("validation.user.firstname.minlength"),    
          maxlength: I18n.t("validation.user.firstname.maxlength")    
			  },
        "user[lastname]":{
		  		required: I18n.t("validation.user.lastname.blank"),
          minlength: I18n.t("validation.user.lastname.minlength"),    
          maxlength: I18n.t("validation.user.lastname.maxlength")    
			  },
			  "user[email]":{
		  		required: I18n.t("validation.user.email.blank"),
          email: I18n.t("validation.user.email.format"),          
			  },
				"user[password]":{
					required: I18n.t("validation.user.password.blank"),
					minlength: I18n.t("validation.user.password.minlength"),
			  },
			  "user[password_confirmation]":{
					required: I18n.t("validation.user.password.blank"),
					minlength: I18n.t("validation.user.password.minlength"), 
					equalTo: I18n.t("validation.user.password.match_password")
			  }, 
			}
		});
	}

});