$(document).on('turbo:load', function() {
  $("body").delegate("#select_all_copy_options", "click", function(){
		$("input[class='copy_option_item']").prop('checked', this.checked);
  })

	$("body").delegate(".copy_option_item", "click", function(){
		if( $(".list_option").find("input[class='copy_option_item']:checked").length == $(".list_option").find("input[class='copy_option_item']").length ) {
			$("#select_all_copy_options").prop('checked', true);
		} else {
			$("#select_all_copy_options").prop('checked', false);
		}
  })

	$("body").delegate("#btnDeleteTemplate", "click", function(){
		var arr_template = [];
		$.each($('.template_table tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_template_table']:checked").is(":checked")) {
				arr_template.push($(row).data('template-id'));
			}
		});

		if (arr_template.length > 0) {
			let page_value = $("#template_table_box").find(".pagination .current").text().trim();

			$.ajax ({
				type: 'GET',
				url: "/project_manager/templates/confirm_destroy_template",
				dataType: 'script',
				data: {
					id: arr_template,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					//
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate("#select_all_column_options", "click", function(){
    $("input[class='checkbox_template_table']").prop('checked', this.checked);
  })

  $("body").delegate(".checkbox_template_table", "click", function(){
    set_select_all_option(".template_table", "checkbox_template_table")
  })
})
