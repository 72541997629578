$(document).on('turbo:load', function () {
    $('#form_setting_interaction_id').submit(function() {
        $('#user_selected_edit option').prop('selected', true);
        return true; 
    });
  
    // $("body").delegate('#lstDepartmentFilter', 'change', function() {
    //     var listIdSelected = [];
    //     $("#user_selected_edit option").each(function()     
    //     {
    //         listIdSelected.push($(this).val());
    //     });
    //     $.ajax({
    //       type: 'GET',
    //       url: "/search_users_by_department",
    //       dataType: 'script',
    //       data: {
    //         deparment_id: $("#lstDepartmentFilter option:selected").val(),
    //         lstUserSelected: listIdSelected
    //       },
    //       success: function(data, textStatus, jqXHR){
            
    //       },
    //       error:function(jqXHR, textStatus, errorThrown){
    //         console.log("AJAX Error: #{textStatus}")
    //       }
    //     });
    //   });
      

      $("body").delegate('#btnMoveUpSettingInteracter', 'click', function () {
        $('#user_selected_edit option:selected').each(function (i, selected) {
          if (!$(this).prev().length) return false;
          $(this).insertBefore($(this).prev());
        });
        $('#user_selected_edit select').focus().blur();
      });
      
      $("body").delegate('#btnMoveDownSettingInteracter', 'click', function () {
        $($('#user_selected_edit option:selected').get().reverse()).each(function (i, selected) {
          if (!$(this).next().length) return false;
          $(this).insertAfter($(this).next());
        });
        $('#user_selected_edit select').focus().blur();
      });
      
      $("body").delegate('#btnMoveTopSettingInteracter', 'click', function () {
        var count_category = 0;
        $.each($('#user_selected_edit option:selected'), function (i, row) {
          count_category = count_category + 1
        });
        if (count_category > 0){
          var first_elem = $("#user_selected_edit option:first");
          if(first_elem.is(':selected')) return true;
          while(!first_elem.prev().length){
            $('#user_selected_edit option:selected').each(function (i, selected) {
              if (!$(this).prev().length) return false;
              $(this).insertBefore($(this).prev());
            });
            $('#user_selected_edit select').focus().blur();
          }	
        }
      });
      
      $("body").delegate('#btnMoveBottomSettingInteracter', 'click', function () {	
        var count_category = 0;
        $.each($('#user_selected_edit option:selected'), function (i, row) {
          count_category = count_category + 1
        });
        if (count_category > 0){
          var last_elem = $("#user_selected_edit option:last");
          if(last_elem.is(':selected')) return true;
          while(!last_elem.next().length){
            $($('#user_selected_edit option:selected').get().reverse()).each(function (i, selected) {
              if (!$(this).next().length) return false;
              $(this).insertAfter($(this).next());
            });
            $('#user_selected_edit select').focus().blur();
          }
        }
      });  

      $("body").delegate("#user_system_setting #table_row_number", "input", function (e){
        var objectDataSubmit = {name: this.id,value: this.value};
        AjaxSubmit( "/user_settings/save_personal_config",'POST',objectDataSubmit);  
      });

      $("body").delegate("#setting_notify_mail_by_module .form-check-input", "input", function (e){
        document.querySelectorAll('#setting_notify_mail_by_module .form-check-input').forEach(elem => {
          elem.disabled = true;
        });
        var objectDataSubmit = {name: this.id,value: this.checked};
          $.ajax({
            type: 'POST',
            url: "/user_settings/save_personal_config",
            dataType: 'script',
            data: objectDataSubmit,
            success: function (data, textStatus, jqXHR) {
              document.querySelectorAll('#setting_notify_mail_by_module .form-check-input').forEach(elem => {
                elem.disabled = false;
              });
            },
            error: function (jqXHR, textStatus, errorThrown) {
               window.location.reload();
            }
          });
        
        });
});

