$(document).on('turbo:load', function(){
    $(".notifications_icon").on("click", function(){
        // $.post("/read", function(data){
        //   // remove unread count
        // //   $('#open_notification').empty();
        // });
    });

    $("body").delegate('.noti-close-btn', 'click', function(e) {
        // OR
        var objectDataSubmit = {id: parseInt(this.closest('.notify-item').getAttribute('data-id'))};
        AjaxSubmit("/delete_selected_notify",'GET',objectDataSubmit);          
    });

    $("body").delegate('.noti-close-btn', 'click', function(e) {
        // OR
        var objectDataSubmit = {id: parseInt(this.getAttribute('data-id'))};
        AjaxSubmit("/delete_selected_notify",'GET',objectDataSubmit);          
    });
    $("body").delegate('.notify-item .notify-url', 'click', function(e) {
        var url = this.getAttribute('data-url');
        // OR
        $.ajax({
            type: 'GET',
            url: "/read_notify",
            dataType: 'script',
            data: {
                id: parseInt(this.closest('.notify-item').getAttribute('data-id'))
            },
            success: function (data, textStatus, jqXHR) {           
                window.location.href = window.origin + url;
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error");
            }
        });
    });
    

    $("body").delegate('#notifi_clear_all', 'click', function(e) {
        e.stopPropagation();
        // OR
        e.preventDefault();
        var user_id = $(this).val();
        $.ajax({
            type: 'GET',
            url: "/delete_all_notifications",
            dataType: 'script',
            data: {
                id: user_id
            },
            success: function (data, textStatus, jqXHR) {                
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error");
            }
        });
    });
    $(".notify_user").click(function() {
        var id_notify = this.querySelector("#id").value;
        $.ajax({
            type: 'GET',
            url: "/change_status_notify",
            dataType: 'script',
            data: {
                id: id_notify
            },
            success: function (data, textStatus, jqXHR) {
                ;
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error");
            }
        });
    });
});