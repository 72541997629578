import { Sortable, AutoScroll } from 'sortablejs';

import { find_list_user_in_storage, set_item_user_in_storage } from './schedule.js';
import { set_item_equipment_in_storage, find_list_equipment_in_storage } from './schedule_equipment.js';
$(document).ready(function(){
	var list_user, list_equipments; 
	if (document.getElementById("user_id")) {
		list_user = find_list_user_in_storage(document.getElementById("user_id").value);
	  list_user = list_user.split(",");

		list_equipments = find_list_equipment_in_storage(document.getElementById("user_id").value);
	  list_equipments = list_equipments.split(",");
	}
});

$(document).on('turbo:load', function () {
	$("body").delegate('#pre_week', 'click', function () {
			var pre_week = $("#pre_week").attr("value");
			changeStartDate(pre_week);
	});

	$("body").delegate('#pre_day', 'click', function () {
		var pre_day = $("#pre_day").attr("value");
		changeStartDate(pre_day);
	});

	$("body").delegate('#current_day', 'click', function () {
		var current_day = $("#current_day").attr("value");
		changeStartDate(current_day);
	});

	$("body").delegate('#next_day', 'click', function () {
		var next_day = $("#next_day").attr("value");
		changeStartDate(next_day);
	});

	$("body").delegate('#next_week', 'click', function () {
		var next_week = $("#next_week").attr("value");
		changeStartDate(next_week);
	});

	$("body").delegate('#btnSaveSimpleScheduleDashboard', 'click', function (e) {
		var time_start_hour = $("#schedule_time_start_hour").val();
		var time_end_hour = $("#schedule_time_end_hour").val();
		var date = $('#schedule_date').val();
		var time_start_minutes = $("#schedule_time_start_minutes").val().padStart(2, '0');
		var time_end_minutes = $("#schedule_time_end_minutes").val().padStart(2, '0');
		var start_time = new Date(date + "T" + time_start_hour + ":" + time_start_minutes);
		var end_time = new Date(date + "T" + time_end_hour + ":" + time_end_minutes);

		if ((start_time >= end_time) && (time_end_hour != '')) {
			$("#error_simple_schedule_dashboard").text("開始時刻が不正です。");
			if ($("#error_simple_schedule_dashboard").hasClass("d-none")) {
				$("#error_simple_schedule_dashboard").removeClass('d-none');
			}
		}
		else if (time_start_hour == '' && time_end_hour != '') {
			$("#error_simple_schedule_dashboard").text("開始時刻が入力されていません。");
			if ($("#error_simple_schedule_dashboard").hasClass("d-none")) {
				$("#error_simple_schedule_dashboard").removeClass('d-none');
			}
		}
		else if(isNaN(end_time)) {
			$("#error_simple_schedule_dashboard").text("時刻が不正です。");
			if ($("#error_simple_schedule_dashboard").hasClass("d-none")) {
				$("#error_simple_schedule_dashboard").removeClass('d-none');
			}
		}
		else if ($("#equipment_id").length > 0 && time_start_hour == "" && time_start_minutes == "" && time_end_hour == "" && time_end_minutes == "") {
      $("#error_simple_schedule_dashboard").text("施設を予約する場合は、時刻を入力してください。");
      $("#error_simple_schedule_dashboard").removeClass('d-none');
    }
		else {
			document.getElementById("btnSave_simple_schedule_dashboard").click();
		}
	});
 
		$('#carouselExampleIndicators1').on('slid.bs.carousel', function () {
		});

	$("body").delegate('#pdf_db_option_1', 'click', function () {
		$("#pdf_db_option_1").removeClass("mdi-checkbox-blank-circle-outline");
		$("#pdf_db_option_1").addClass("mdi-circle-slice-8");
		$("#pdf_db_option_2").removeClass("mdi-circle-slice-8");
		$("#pdf_db_option_2").addClass("mdi-checkbox-blank-circle-outline");
	});  
	$("body").delegate('#pdf_db_option_2', 'click', function () {
		$("#pdf_db_option_2").removeClass("mdi-checkbox-blank-circle-outline");
		$("#pdf_db_option_2").addClass("mdi-circle-slice-8");
		$("#pdf_db_option_1").removeClass("mdi-circle-slice-8");
		$("#pdf_db_option_1").addClass("mdi-checkbox-blank-circle-outline");
	});

	$("body").delegate('#tbl_mail_dashboard tbody tr td', 'click', function () {
			if (!$(this).hasClass("dataTables_empty")){
				select_row_table_mail_dashboard(this); 
			}
	});
	let dashboard_time = document.getElementById('dashboard_time');
	if(dashboard_time){
		updateTime();
		setInterval(updateTime, 1000);
	}
	
	formatTables();
	definde_slide();
	call_module_card_zone();

	$("body").delegate('#btnUpdateDashboardScheduleSetting', 'click', function () {
		$("#btnUpdate_dashboard_schedule_setting").click();
	});

	$("body").delegate('.move-item-dashboard', 'click', function (e) {
		var current_module_card = $(this).closest(".dashboard-card");
		if (current_module_card.find(".card").hasClass("image-theme-selected")){
			current_module_card.addClass("filtered-module-card");
			current_module_card.find(".card").removeClass("image-theme-selected");
		}else{
			$(".dashboard-card").addClass("filtered-module-card");
			$(".dashboard-card").find(".card").removeClass("image-theme-selected");
			current_module_card.removeClass("filtered-module-card");
			current_module_card.find(".card").addClass("image-theme-selected");
		}
	});

	$("body").delegate('#show_selected_module_card', 'click', function () {
		var selected_option = parseInt( $("#list_modules_active option:selected").attr("data-item-id"));
		var start_date = $("#text_fulldate_dashboard").attr("value");
		var list_id_existed = [];
		var cards = document.querySelectorAll('.dashboard-card');
		for (let index = 0; index < cards.length; index++) {
			list_id_existed.push(parseInt(cards[index].getAttribute('data-item-id')));			
		} 
		var existed = list_id_existed.includes(selected_option);
		var stamp_card = document.querySelector('#stamp_card');
		var exist_tamp = false;
		if(stamp_card){
			exist_tamp = true;
		}
		$.ajax({
			type: "GET",
			url: "/add_selected_module_card",
			dataType: "script",
			data: { 
				module_id: selected_option,
				start_date: start_date,
				exist: existed
			},
			success: function (data, textStatus, jqXHR) {				
				if(existed){
					document.querySelector(`.card_zone_${selected_option}`).classList.remove('d-none');
				}
				else{
					formatTables();
					if(!exist_tamp){
						definde_slide();
					}
				}

			},
			error: function (jqXHR, textStatus, errorThrown) {
				call_module_card_zone();
				// if (selected_option === '4'){
				// 	$("#stamps_card_zone").find(".card").addClass("image-theme-selected");
				// 	$("#stamps_card_zone").find(".dashboard-card").removeClass("filtered-module-card");
				// 	definde_slide();
				// }else if (selected_option === '1'){
				// 	$("#bulletin_card_zone").find(".card").addClass("image-theme-selected");
				// 	$("#bulletin_card_zone").find(".dashboard-card").removeClass("filtered-module-card");
				// 	definde_slide();
				// }else if (selected_option === '6'){
				// 	$("#mail_card_zone").find(".card").addClass("image-theme-selected");
				// 	$("#mail_card_zone").find(".dashboard-card").removeClass("filtered-module-card");
				// 	definde_slide();
				// }else if (selected_option === '3'){
				// 	var check_selected_option_schedule = $("#choose_schedule_setting_item").find('option:selected').val();
				// 	if (check_selected_option_schedule === '0_department_option'){
				// 		check_selection_user_schedule_dashboard();
				// 	}
				// 	else if (check_selected_option_schedule === '0_category_option'){
				// 		check_selection_equipment_schedule_dashboard();
				// 	}
				// };
			}
		});
	});

	$("body").delegate('#choose_schedule_setting_item', 'change', function () {
		var selected_option = $(this).val();
		var data_type = $("#choose_schedule_setting_item option:selected").attr('data-type');
		var start_date = $("#text_fulldate_dashboard").attr("value");
		if (typeof data_type === 'undefined' && data_type === false) {
			data_type = '';
		}
		if (selected_option === '0_department_option'){
			check_selection_user_schedule_dashboard(selected_option, start_date);
		}else if (selected_option === '0_category_option') {
			check_selection_equipment_schedule_dashboard(selected_option, start_date);
		}else{
			$.ajax({
				type: "GET",
				url: "update_dashboard_schedule_setting",
				dataType: "script",
				data: { 
					dashboard_schedule_setting_item_selected: selected_option,
					data_type: data_type,
					start_date: start_date
				},
				success: function (data, textStatus, jqXHR) {
				},
				error: function (jqXHR, textStatus, errorThrown) {
				}
			});
		}

	});


	$("body").delegate('#btnUpdate_dashboard_pdf_setting','click', function(event) {
		var show_forwarder = $("#show_forwarder_pdf_setting").is(':checked');
		var show_next_approver = $("#show_next_approver_pdf_setting").is(':checked');
		var show_status = $("#show_status_pdf_setting").is(':checked');
		var rows = $("#dashboard_pdf_setting_rows").val();
		$.ajax({
			type: "GET",
			url: "/update_dashboard_pdf_setting",
			dataType: "script",
			data: { 
				show_forwarder: show_forwarder,
				show_next_approver: show_next_approver,
				show_status: show_status,
				rows: rows,
			},
			success: function (data, textStatus, jqXHR) {
				formatTableMyPdf();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error");
				formatTables();
			}
		});
	});

	$("body").delegate('#btnUpdate_dashboard_list_of_transfer_setting','click', function(event) {
		var id = $("#dashboard_list_of_transfer_setting_id").val();
		var show_forwarder = $("#show_forwarder_list_of_transfer_setting").is(':checked');
		var show_status = $("#show_status_list_of_transfer_setting").is(':checked');
		var rows = $("#dashboard_list_of_transfer_setting_rows").val();
		$.ajax({
			type: "GET",
			url: "/update_dashboard_list_of_transfers_setting",
			dataType: "script",
			data: { 
				id: id,
				show_forwarder: show_forwarder,
				show_status: show_status,
				rows: rows},
			success: function (data, textStatus, jqXHR) {
				formatTableSharePdf();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error");
			}
		});
	});

	$("body").delegate('#btnUpdate_dashboard_pdf_complete_setting','click', function(event) {
		var id = $("#dashboard_pdf_complete_setting_id").val();
		var show_last_approver = $("#show_last_approver_pdf_complete_setting").is(':checked');
		var show_last_approver_date = $("#show_last_approver_date_pdf_complete_setting").is(':checked');
		var rows = $("#dashboard_pdf_complete_setting_rows").val();
		$.ajax({
			type: "GET",
			url: "/update_dashboard_pdf_complete_setting",
			dataType: "script",
			data: { 
				id: id,
				show_last_approver: show_last_approver,
				show_last_approver_date: show_last_approver_date,
				rows: rows},
			success: function (data, textStatus, jqXHR) {
				formatTableCompletePdf();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error");
			}
		});
	});

	$("body").delegate('#edit_pdf_view_dashboard', 'click', function () {
		var id = $(this).attr("data-item-id");
		$.ajax({
			type: "GET",
			url: "/edit_pdf_view_dashboard",
			dataType: "script",
			data: { 
				id: id},
			success: function (data, textStatus, jqXHR) {
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error")
			}
		});
	});

	$("body").delegate('#edit_list_of_transfers_view_dashboard', 'click', function () {
		var id = $(this).attr("data-item-id");
		$.ajax({
			type: "GET",
			url: "/edit_list_of_transfers_view_dashboard",
			dataType: "script",
			data: { 
				id: id},
			success: function (data, textStatus, jqXHR) {
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error")
			}
		});
	});

	$("body").delegate('#edit_pdf_complete_view_dashboard', 'click', function () {
		var id = $(this).attr("data-item-id");
		$.ajax({
			type: "GET",
			url: "/edit_pdf_complete_view_dashboard",
			dataType: "script",
			data: { 
				id: id},
			success: function (data, textStatus, jqXHR) {
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("error")
			}
		});
	});


	// button filter schedule by list user
	$("body").delegate('#btnFilterEquipmentDashboard', 'click', function () {
		// var data_type = $("#data_type").val();
		var start_date = $("#text_fulldate_dashboard").attr("value");
		var list_equipments = find_list_equipment_in_storage(document.getElementById("user_id").value);
    list_equipments = list_equipments.split(",");
		$.ajax({
			type: 'GET',  
			url: "/select_list_equipment_dashboard",
			dataType: 'script',
			data: { 
				data_type: "1",
				list_equipments: list_equipments,
				start_date: start_date,
			},
			success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});

	$("body").delegate('#btnFilterUserDashboard', 'click', function () {
		// var data_type = $("#data_type").val();
		var start_date = $("#text_fulldate_dashboard").attr("value");
		var list_user = find_list_user_in_storage(document.getElementById("user_id").value);
		list_user = list_user.split(",");
		$.ajax({
			type: 'GET',  
			url: "/select_list_user_dashboard",
			dataType: 'script',
			data: { 
				data_type: "1",
				list_user: list_user,
				start_date: start_date,
			},
			success: function(data, textStatus, jqXHR){
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});

// $("#btn_filter_schedule_user").click(function() {
	$("body").delegate('#btn_filter_schedule_user_dashboard', 'click', function (e) {
		var user_id = document.getElementById("user_id").value;
		var list_user_selected = [];
		var options_user = document.getElementById("user_selected_schedule_dashbooard_modal");
		for (var i=0; i<options_user.length; i++) {
			options_user[i].selected = "true";
			list_user_selected.push(options_user[i].value);
		}
		set_item_user_in_storage(list_user_selected, user_id);
	});

	$("body").delegate('#select_department_schedule_dashboard_modal', 'change', function() {
		var options_user = document.getElementById("user_selected_schedule_dashbooard_modal");
		var department_id = $("#select_department_schedule_dashboard_modal option:selected").val().split("_")[0];
		var data_type = $("#select_department_schedule_dashboard_modal option:selected").attr('data-type');
		var list_user_selected = [];
		for (var i=0; i<options_user.length; i++) {
			list_user_selected.push(options_user[i].value);
		}
		$.ajax({
			type: 'GET',
			url: "/filter_list_user_dashboard_modal",
			dataType: 'script',
			data: {
				department_id: department_id,
				list_user: list_user_selected,
				data_type: data_type
			},
			success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error")
			}
		});
	});

	$("body").delegate('#add_participant_dashboard_modal', 'click', function() {
		var list_user_select = document.getElementById('list_user_schedule_dashboard_modal');
		var list_user_selected = document.getElementById('user_selected_schedule_dashbooard_modal');
		if (list_user_select.length > 0) {
			moveOption(list_user_select, list_user_selected);
		}
	});

	// $("#remove_participant").click( function() {
	$("body").delegate('#remove_participant_dashboard_modal', 'click', function() {
		var list_user_select = document.getElementById('list_user_schedule_dashboard_modal');
		var list_user_selected = document.getElementById('user_selected_schedule_dashbooard_modal');
		moveOption(list_user_selected, list_user_select);
	});

	var check_selected_option_schedule = $("#choose_schedule_setting_item").find('option:selected').val();
	if (check_selected_option_schedule === '0_department_option'){
		check_selection_user_schedule_dashboard();
	}
	else if (check_selected_option_schedule === '0_category_option'){
		check_selection_equipment_schedule_dashboard();
	}

	$("body").delegate('#btn_filter_schedule_equipment_dashboard', 'click', function (e) {
		var user_id = document.getElementById("user_id").value;
		var lst_equipment_selected = [];
		var options_equipment = document.getElementById("equipment_selected_schedule_dashboard_modal");
		for (var i=0; i<options_equipment.length; i++) {
			options_equipment[i].selected = "true";
			lst_equipment_selected.push(options_equipment[i].value);
		}
		set_item_equipment_in_storage(lst_equipment_selected, user_id);
	});

	// move equipment screen normal schedule
	$("body").delegate('#add_equipment_dashboard_modal', 'click', function() {
		var lst_equipment_select = document.getElementById('list_equipment_schedule_dashboard_modal');
		var lst_equipment_selected = document.getElementById('equipment_selected_schedule_dashboard_modal');
		if (lst_equipment_select.length > 0) {
			moveOption(lst_equipment_select, lst_equipment_selected);
		}
	});

	$("body").delegate('#remove_equipment_dashboard_modal', 'click', function() {
		var lst_equipment_select = document.getElementById('list_equipment_schedule_dashboard_modal');
		var lst_equipment_selected = document.getElementById('equipment_selected_schedule_dashboard_modal');
		moveOption(lst_equipment_selected, lst_equipment_select);
	});

	$("body").delegate('#select_category_schedule_dashboard_modal', 'change', function() {
		var options_equipment = document.getElementById("equipment_selected_schedule_dashboard_modal");
		var lst_equipment_selected = [];
		for (var i=0; i<options_equipment.length; i++) {
			lst_equipment_selected.push(options_equipment[i].value);
		}
		$.ajax({
			type: 'GET',
			url: "/filter_list_equipment_dashboard_modal",
			dataType: 'script',
			data: {
				category_id: $("#select_category_schedule_dashboard_modal option:selected").val(),
				list_equipments: lst_equipment_selected
			},
			success: function(data, textStatus, jqXHR){
				// console.log("AJAX OK")
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error")
			}
		})
	});
	
	var check_hide_stamp = $('.check-body-stamp-dashboard');
	if (check_hide_stamp.hasClass('d-none')){
		$('.slick-dots').addClass('d-none');
		$('.slick-dots').removeClass('pb-1');
	}

	$("body").delegate('#btnUpdate_dashboard_bulletin_setting', 'click' , function(){
		var dashboard_bulletin_setting_item_selected  = $("#update_schedule_setting_item option:selected").val();
		var bulletins_show_records = $("#bulletins_show_records option:selected").val();
		// var show_content = $("#bulletins_dashboard_show_content").is(':checked');
		var show_name = $("#bulletins_dashboard_show_name").is(':checked');
		var show_time = $("#bulletins_dashboard_show_time").is(':checked');
		$.ajax({
			type: 'GET',
			url: "/update_dashboard_bulletin_setting",
			dataType: 'script',
			data: {
				dashboard_bulletin_setting_item_selected: dashboard_bulletin_setting_item_selected,
				bulletins_show_records: bulletins_show_records,
				// show_content: show_content,
				show_name: show_name,
				show_time: show_time
			},
			success: function(data, textStatus, jqXHR){
				// console.log("AJAX OK")
				formatTableBulletin();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error")
			}
		})
	});

	$("body").delegate('#btnUpdate_dashboard_mail_setting', 'click' , function(){
		var mail_dashboard_folder_id  = $("#mail_dashboard_folder_id option:selected").val();
		var mails_show_records = $("#mails_show_records option:selected").val();
		var mail_dashboard_time_type = $("#mail_dashboard_time_type_true").is(':checked');
		var mail_dashboard_show_name = $("#mail_dashboard_show_name").is(':checked');
		var mail_dashboard_show_time = $("#mail_dashboard_show_time").is(':checked');
		$.ajax({
			type: 'GET',
			url: "/update_dashboard_mail_setting",
			dataType: 'script',
			data: {
				mail_dashboard_folder_id: mail_dashboard_folder_id,
				mails_show_records: mails_show_records,
				mail_dashboard_time_type: mail_dashboard_time_type,
				mail_dashboard_show_name: mail_dashboard_show_name,
				mail_dashboard_show_time: mail_dashboard_show_time
			},
			success: function(data, textStatus, jqXHR){
				formatTableMail();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error")
			}
		})
	});

	// setting show column and count record data management in dashboard
	$("body").delegate('#btnUpdate_dashboard_data_management_setting', 'click' , function(){
		var data_management_show_records = $("#data_management_show_records option:selected").val();
		var show_name = $("#data_management_dashboard_show_name").is(':checked');
		var show_time = $("#data_management_dashboard_show_time").is(':checked');
		$.ajax({
			type: 'GET',
			url: "/update_dashboard_data_management_setting",
			dataType: 'script',
			data: {
				data_management_show_records: data_management_show_records,
				show_name: show_name,
				show_time: show_time,
			},
			success: function(data, textStatus, jqXHR){
				// console.log("AJAX OK")
				formatTableDataManagement();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("Error")
			}
		})
	});

	// setting show column and count record file management in dashboard
	$("body").delegate('#btnUpdate_dashboard_file_management_setting', 'click' , function(){
		var file_management_show_records = $("#file_management_show_records option:selected").val();
		var show_user_created = $("#file_management_dashboard_show_user_created").is(':checked');
		var show_time = $("#file_management_dashboard_show_time").is(':checked');
		$.ajax({
			type: 'GET',
			url: "/update_dashboard_file_management_setting",
			dataType: 'script',
			data: {
				file_management_show_records: file_management_show_records,
				show_user_created: show_user_created,
				show_time: show_time,
			},
			success: function(data, textStatus, jqXHR){
				// console.log("AJAX OK")
				formatTableFileManagement();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("Error")
			}
		})
	});


	
}); 

function move_item_handle(evt, display_position){
	if (evt.to === evt.from){
		if (evt.from.id === "draggable_zone_1"){
			display_position = 0; //left
		} else {
			display_position = 1; //right
		}
	} else
	{
		if (evt.from.id === "draggable_zone_1"){
			display_position = 1; //right
		} else {
			display_position = 0; //right
		}
	}
	var list_draggable_zone_1 = $("#draggable_zone_1").find(".dashboard-card");
	var list_draggable_zone_2 = $("#draggable_zone_2").find(".dashboard-card");
	var list_id_zone_1 = [],  list_id_zone_2=[];
	var current_module_card = $(evt.item).find(".dashboard-card").attr("data-item-id");
	$.each(list_draggable_zone_1, function (i, row) {
		list_id_zone_1.push($(row).attr("data-item-id"));
	});
	$.each(list_draggable_zone_2, function (i, row) {
		list_id_zone_2.push($(row).attr("data-item-id"));
	});
	$.ajax({
		type: "GET",
		url: "/save_module_card_position",
		dataType: "script",
		data: { 
			display_position: display_position, 
			list_id_zone_1: list_id_zone_1, 
			list_id_zone_2: list_id_zone_2,
			current_module_card: current_module_card},
		success: function (data, textStatus, jqXHR) {
			call_module_card_zone();
			definde_slide();
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("error")
		}
	});
};

function updateTime() {
	var serverTime = new Date();
	var hours = serverTime.getHours();
	if (hours < 4){
		$('#dashboard_time').html(I18n.t('dashboards.helper.night'),);
	} else if (hours < 12) {
		$('#dashboard_time').html(I18n.t('dashboards.helper.morning'),);
	} else if (hours < 18) {
		$('#dashboard_time').html(I18n.t('dashboards.helper.afternoon'),);
	} else {
		$('#dashboard_time').html(I18n.t('dashboards.helper.evening'),);
	}
	
};
 
function changeStartDate(start_date){
	var check_selected_option_schedule = $("#choose_schedule_setting_item").val();
	var list_equipments;
	if (check_selected_option_schedule === '0_department_option'){
		var list_user = find_list_user_in_storage(document.getElementById("user_id").value);
    list_user = list_user.split(",");
	}
	else if (check_selected_option_schedule === '0_category_option'){
		var list_equipments = find_list_equipment_in_storage(document.getElementById("user_id").value);
    list_equipments = list_equipments.split(",");
	}
	$.ajax({
		type: "GET",
		url: "change_start_date_schedule_day",
		dataType: "script",
		data: { start_date: start_date,
			list_user: list_user,
			list_equipments: list_equipments,
		 },
		success: function (data, textStatus, jqXHR) {
			
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log(jqXHR.responseText)
		}
	})
};



window.select_row_table_mail_dashboard = function(row_select) {
var id_mail_selected = row_select.parentElement.dataset.itemId;
var fid = $("#fid").attr("value")
var nextURL = window.location.origin + "/personal_mails?fid=" + fid + "&personal_mail_id=" + id_mail_selected;
const nextTitle = '';
const nextState = '';
window.history.pushState(nextState, nextTitle, nextURL);
window.history.replaceState(nextState, nextTitle, nextURL);
window.location.reload();

}
window.call_module_card_zone = function() {
	var draggable_zone_1 = document.getElementById("draggable_zone_1");
	var draggable_zone_2 = document.getElementById("draggable_zone_2");
	var display_position = "";

	if (draggable_zone_1){
		new Sortable.create(draggable_zone_1, {
			group: 'shared', 
			filter: ".filtered-module-card", 
			forceFallback: true,
			onEnd: function (/**Event*/evt) {
				$(evt.item).removeClass("image-theme-selected");
				move_item_handle(evt, display_position);
			},
		});
	}

	if (draggable_zone_2){
		new Sortable.create(draggable_zone_2, {
				group: 'shared',
				filter: ".filtered-module-card", 
				forceFallback: true,
				onEnd: function (/**Event*/evt) {
					$(evt.item).removeClass("image-theme-selected");
					move_item_handle(evt, display_position);
				},
		});
	}

	
	$(".dashboard-card").addClass("filtered-module-card");
}
export function moveOption(moveOptionFrom, moveOptionTo) {
	$(moveOptionFrom).find('option:selected').detach().prop("selected", false).appendTo($(moveOptionTo));
}

function check_selection_user_schedule_dashboard(selected_option, start_date) {
		var list_user = find_list_user_in_storage(document.getElementById("user_id").value);
		list_user = list_user.split(",");
		$.ajax({
			type: 'GET',
			url: "/filter_list_selected_user_dashboard_modal",
			dataType: 'script',
			data: {
				list_user: list_user,
				data_type: "1",
				selected_option: selected_option,
				start_date: start_date,
			},
			success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error")
			}
		});
}

function check_selection_equipment_schedule_dashboard(selected_option, start_date){
	var list_equipments = find_list_equipment_in_storage(document.getElementById("user_id").value);
	list_equipments = list_equipments.split(",");
	$.ajax({
		type: 'GET',
		url: "/filter_list_selected_equipment_dashboard_modal",
		dataType: 'script',
		data: {
			list_equipments: list_equipments,
			data_type: "1",
			selected_option: selected_option,
			start_date: start_date,
		},
		success: function(data, textStatus, jqXHR){
			
		},
		error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error")
		}
	});
}

function formatTables(){	
	formatTableMyPdf();
	formatTableSharePdf();
	formatTableCompletePdf();
	formatTableMail();	
	formatTableBulletin();
	formatTableDataManagement();
	formatTableFileManagement();
	
}
function formatTableMyPdf(){
	var tblPdfDashboard = document.getElementsByClassName("tbl-pdf-dashboard");
	if (tblPdfDashboard.length > 0) {

			var pdf_dashboard = window.$('.tbl-pdf-dashboard').DataTable({
				'destroy': true,
				'columnDefs': [
				 { "width": "auto", 
				 "targets": 1,
				 "render": function ( data, type, row ) {
					return '<span style="white-space:normal">' + data + "</span>";
				}
				},
				],
				drawCallback: function () {
						$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language: {
						paginate: {
								previous: "<i class='mdi mdi-chevron-left'>",
								next: "<i class='mdi mdi-chevron-right'>"
						}
				},
				scrollCollapse: true,
				fixedColumns: {
						leftColumns: 1
				},
				"searching":false,
				"paging":false,
				"bInfo" : false,
			}).columns.adjust().responsive.recalc();
	};
}
function formatTableSharePdf(){
	var tblPdfSharedDashboard = document.getElementsByClassName("tbl-pdf-shared-table-dashboard");
	if (tblPdfSharedDashboard.length > 0) {
			var trasfers_dashboard = window.$('.tbl-pdf-shared-table-dashboard').DataTable({
				'destroy': true,
				'columnDefs': [
						{ "width": "35%", "targets": 0 },
				],
				drawCallback: function () {
						$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language: {
						paginate: {
								previous: "<i class='mdi mdi-chevron-left'>",
								next: "<i class='mdi mdi-chevron-right'>"
						}
				},
				scrollCollapse: true,
				fixedColumns: {
						leftColumns: 1
				},
				"searching":false,
				"paging":false,
				"bInfo" : false,
			}).columns.adjust().responsive.recalc();
	};
}
function formatTableCompletePdf(){
	var tblCompleteDashboard = document.getElementsByClassName("tbl-pdf-complete-dashboard");
	if (tblCompleteDashboard.length > 0) {

			var complete_dashboard = window.$('.tbl-pdf-complete-dashboard').DataTable({
				'destroy': true,
				'columnDefs': [
						{ "width": "35%", "targets": 0 },
				],
				drawCallback: function () {
						$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language: {
						paginate: {
								previous: "<i class='mdi mdi-chevron-left'>",
								next: "<i class='mdi mdi-chevron-right'>"
						}
				},
				scrollCollapse: true,
				fixedColumns: {
						leftColumns: 1
				},
				"searching":false,
				"paging":false,
				"bInfo" : false,
			}).columns.adjust().responsive.recalc();
	};
}

function formatTableMail(){
	var tblMailDashboard = document.getElementsByClassName("tbl-mail-dashboard");
	if (tblMailDashboard.length > 0) {

			var mail_dashboard = window.$('.tbl-mail-dashboard').DataTable({
					'destroy': true,
					'columnDefs': [
							{ "width": "50%", "targets": 0 },
					],
					drawCallback: function () {
							$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language: {
							paginate: {
									previous: "<i class='mdi mdi-chevron-left'>",
									next: "<i class='mdi mdi-chevron-right'>"
							}
					},
					scrollCollapse: true,
					fixedColumns: {
							leftColumns: 1
					},
					"searching":false,
					"paging":false,
					"bInfo" : false,
					order: [[2, 'desc']]
			}).columns.adjust().responsive.recalc();
	};
	
}
function formatTableBulletin(){
	var tblBulletin = document.getElementsByClassName("tbl-bulletin-dashboard");
	if (tblBulletin.length > 0) {
		window.$('.tbl-bulletin-dashboard').DataTable({
			'destroy': true,
			// 'stateSave': true,
			'columnDefs': [
				{ "width": "50%", "targets": 0 },
			],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
			"searching":false,
			scrollCollapse: true,
			"bInfo" : false,
			"paging":false,
			order: [[2, 'desc']]
		}).columns.adjust().responsive.recalc();
	}
}
function definde_slide(){
	$("#stamp_card").slick({
		dots: true,
		prevArrow: false,
		nextArrow: false,
		autoplay: true,
		autoplaySpeed: 6000,
	});
}
function formatTableDataManagement(){
	var tblDataManagement = document.getElementsByClassName("tbl-data-management-dashboard");
	if (tblDataManagement.length > 0) {
		window.$('.tbl-data-management-dashboard').DataTable({
			'destroy': true,
			// 'stateSave': true,
			'columnDefs': [
				{ "width": "40%", "targets": 0 },
			],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
			"searching":false,
			scrollCollapse: true,
			"bInfo" : false,
			"paging":false
		}).columns.adjust().responsive.recalc();
	}
} 

function formatTableFileManagement(){
	var tblFileManagement = document.getElementsByClassName("tbl-file-management-dashboard");
	if (tblFileManagement.length > 0) {
		window.$('.tbl-file-management-dashboard').DataTable({
			'destroy': true,
			// 'stateSave': true,
			'columnDefs': [
				{ "width": "40%", "targets": 0 },
			],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
			"searching":false,
			scrollY: '250px',
			scrollCollapse: true,
			"bInfo" : false,
			"paging":false,
			order: [[2, 'desc']]
		}).columns.adjust().responsive.recalc();
	}
}