$(document).on('turbo:load', function() {
    $('body').delegate('#btn-update-partner', 'click', function(e) {
        update_partner();
    })
})

function  update_partner() {
    var id;
    console.log("success!");
    var listModules = document.getElementById('list_modules').querySelectorAll('input[type=checkbox]')
    var selectedModules = []
    for (let index = 0; index < listModules.length; index++) {
        const element = listModules[index];
        if(element.checked){
            id = parseInt(element.getAttribute('module-id'))
            selectedModules.push(id);
        }
        
    }

    if(selectedModules.length>0){
        document.getElementById('modules').value = selectedModules;
    }
    console.log(selectedModules);
    Rails.fire(document.getElementById('form_update_partner'), 'submit');
  }