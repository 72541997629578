import {PDFArray,PDFString,PDFName,PDFDict,PDFRef,rgb,PageSizes,degrees, Rotation, PDFFont} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { ANNOTATION,link_enum } from './annotation';
import {Config} from '../define/AppConfig';
import { Convert } from '../../../packs/convert';
import { PDFDocument } from 'pdf-lib';

export class PDFSave{    
    constructor(view){
        this.pdfDoc = view.pdfDoc;
        this.fabricpageAnnotations = view.fabricpageAnnotations;
        this.pages = view.file.pages;
        this.pdfView = view
    }
    async saveArrageFile(){
      document.getElementById('modal-arrage-page').querySelector('.btn_close_modal').click();
      var existingPdfBytes = await subSaveArragePages(this.pdfView);

      var lstNumPages = [];
      $.each($('#lstPages .box'), function (i) {
        lstNumPages.push(parseInt(this.getAttribute("num")) + 1);
      });
      var id = $("#pdf_id").val();
      var name = $("#pdf_class_name").val();
      
      var blob = new Blob([existingPdfBytes], {type: "application/pdf"});
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function() {
        // document.getElementById('path').value = reader.result;
        var path = reader.result;
        $.ajax ({
          type: 'PUT',
          url: "/pdf/update_arrange_page",
          dataType: 'script',
          data: {
            id: id,
            name: name,
            path: path,
            lstNumPages: lstNumPages
          },
          success: function(data, textStatus, jqXHR){
            //
          },
          error:function(jqXHR, textStatus, errorThrown){
            console.log("AJAX Error")
          }
        });
        // document.getElementById('lstNumPages').value = lstNumPages;
        // var form = document.querySelector('#frm_update_document');
        // if (form) {
        //   form.dispatchEvent(new Event('submit', {bubbles: true})); 
        // }
      }    
    }
    async save(){  
        var existingPdfBytes = await this.collect_data_to_file();
        var blob = new Blob([existingPdfBytes], {type: "application/pdf"});
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function() {
          document.getElementById('path').value = reader.result;
          var form = document.querySelector('#frm_update_document');
          if (form) {
            form.dispatchEvent(new Event('submit', {bubbles: true})); 
          }
        }      
    }
    async saveMergeFile(){
      var lstPathRight = [];
      var lstNumPage = [];
      var lstPathLeft = [decodeURI(document.querySelector('#url_file').value)];
      var lstPdfDocs = [this.pdfView.pdfDoc];
      var pdfBytes, loadingPage;

      showFormLoading();

      $.each($('#list_pdf_left .fieldset_tag'), function (i, row) {
        lstPathLeft.push(decodeURI(this.getAttribute("data-path")));
      });
      $.each($('#lstRightItems .box'), function (i, row) {
        lstPathRight.push(decodeURI(this.getAttribute("data-path")));
        lstNumPage.push(parseInt(this.getAttribute("num")) + 1);
      });
      for (let index = 1; index < lstPathLeft.length; index++) {
        // pdfBytes = await fetch(lstPathLeft[index]).then((res) => res.arrayBuffer());
        var pdfBytes =  await fetch_data(lstPathLeft[index]); 
        loadingPage = await PDFDocument.load(pdfBytes);
        lstPdfDocs.push(loadingPage);
      }

      var existingPdfBytes = await subSaveMergePages(this.pdfView, lstPathLeft, lstNumPage, lstPathRight, lstPdfDocs);
      if (lstPathRight.length == 0) return;
      
      var id = $("#pdf_id").val();
      var blob = new Blob([existingPdfBytes], {type: "application/pdf"});
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function() {
        var path = reader.result;
        $.ajax ({
          type: 'POST',
          url: window.origin + "/pdf/save_merge_pdf",
          dataType: 'script',
          data: {
            id: id,
            listPath: lstPathRight,
            listNum: lstNumPage,
            path: path,
            currentPath: decodeURI(document.querySelector('#url_file').value)
          },
          success: function (data, textStatus, jqXHR) {
            hideFormLoading();
            window.location.reload();
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        });
      }
    }

    async collect_data_to_file(){
        var i = 0,indexPage;
        var page,form,list_child_canvases;
        await saveRotateFile(this.pdfDoc,this.pdfView.file.pages);
        var fabricpageAnnotations_sorted = this.fabricpageAnnotations;
          for await(const canvas of fabricpageAnnotations_sorted) { 
                list_child_canvases= canvas[1].getObjects();
                indexPage = canvas[0];   
                page = this.pdfDoc.getPage(indexPage);
                form = this.pdfDoc.getForm();               
                await saveEditControls(form,page,indexPage,this);
                await saveAnnotationFormatText(page,indexPage,this.pdfView);
                // console.log("page " + canvas[0] + "has " + list_child_canvases.length + " children");  
                if(list_child_canvases.length > 0){                         
                  for await(const item of list_child_canvases) {                       
                    if(item.type == 'image')  
                    {    
                      await saveImages(this.pdfDoc,item,page,this.pdfView.currentScaleValue);
                    } 
                    
                    if(item.type == "i-text")
                    {
                      await saveText(this.pdfView,item,page);
                    }
                    if(item.type == "path")
                    {
                      savePath(item,page,this.pdfView.currentScaleValue);
                    }
                    if(item.type == "rect")
                    {          
                      if(item.typyObject == 'link')  
                      {
                        await saveLink(this.pdfDoc,item,page,this.pdfView.currentScaleValue);
                      }  
                      if(item.typyObject == 'rectangle')  
                      {
                        await saveRectangle(item,page,this.pdfView.currentScaleValue);
                      }   
                    }
                    if(item.type == 'line')  
                    {
                      await saveLine(item,page,this.pdfView.currentScaleValue);
                    }  
                    if(item.type == 'ellipse')  
                    {
                      await saveEllipse(item,page,this.pdfView.currentScaleValue);
                    }              
                  }  
                }      
                i++;               
         }           
        existingPdfBytes = await this.pdfDoc.save();
        return existingPdfBytes;
      }

}
var RGBvalues = (function() {

  var _hex2dec = function(v) {
      return parseInt(v, 16)
  };

  var _splitHEX = function(hex) {
      var c;
      if (hex.length === 4) {
          c = (hex.replace('#','')).split('');
          return {
              r: _hex2dec((c[0] + c[0])),
              g: _hex2dec((c[1] + c[1])),
              b: _hex2dec((c[2] + c[2]))
          };
      } else {
           return {
              r: _hex2dec(hex.slice(1,3)),
              g: _hex2dec(hex.slice(3,5)),
              b: _hex2dec(hex.slice(5))
          };
      }
  };

  var _splitRGB = function(rgb) {
      var c = (rgb.slice(rgb.indexOf('(')+1, rgb.indexOf(')'))).split(',');
      var flag = false, obj;
      c = c.map(function(n,i) {
          return (i !== 3) ? parseInt(n, 10) : flag = true, parseFloat(n);
      });
      obj = {
          r: c[0],
          g: c[1],
          b: c[2]
      };
      if (flag) obj.a = c[3];
      return obj;
  };

  var color = function(col) {
      var slc = col.slice(0,1);
      if (slc === '#') {
          return _splitHEX(col);
      } else if (slc.toLowerCase() === 'r') {
          return _splitRGB(col);
      } else {
          console.log('!Ooops! RGBvalues.color('+col+') : HEX, RGB, or RGBa strings only');
      }
  };

  return {
      color: color
  };
}());
async function saveText(pdfView,item,page){
    console.log(item.fontFamily);
    // Fetch custom font 
    var fontName = null;
    var customFont = null;
    switch(item.fontFamily){
      case 'MS 明朝':     
        const urlMSGOTHIC = window.location.origin+'/assets/fonts/MSMINCHO.TTF'
        fontName = 'MS-Gothic';
        customFont = await embedFont(pdfView, page, fontName, urlMSGOTHIC);
        await sub_saveText(pdfView,item,page,customFont);   
        break;
      case 'ＭＳ ゴシック':
        const urlhgrskp = window.location.origin+'/assets/fonts/MSGOTHIC.TTF'     
        fontName = 'MS-Mincho';
        customFont = await embedFont(pdfView, page, fontName, urlhgrskp);
        await sub_saveText(pdfView,item,page,customFont);   
        break;
      case 'Times New Roman':
        const url = window.location.origin+'/assets/fonts/times-new-roman.ttf'
        fontName = 'Times-New-Roman';
        customFont = await embedFont(pdfView, page, fontName, url);
        await sub_saveText(pdfView,item,page,customFont);   
        break;
      case 'Arial':
        const urlArial = window.location.origin+'/assets/fonts/Arialn.ttf'
        fontName = 'Arial';
        customFont = await embedFont(pdfView, page, fontName, urlArial);
        await sub_saveText(pdfView,item,page,customFont);   
        break;
    }
    return existingPdfBytes;  
  }
  async function embedFont(pdfView,page, fontFamily, url){
    var customFont = null;
    // var isEmbedded = false;
    // Register the `fontkit` instance
    // Embed our custom font in the document
    // const { Font } = page.node.normalizedEntries();
    
    // Font.entries().forEach(([fontName, fontRef]) => {
    //   if(fontName.encodedName.includes(fontFamily)){
    //     isEmbedded = true;
    //     // return;
    //   }
      
    // })
    // if(!isEmbedded){
      pdfView.pdfDoc.registerFontkit(fontkit)      
      var fontBytes = await fetch_data(url);
      customFont = await pdfView.pdfDoc.embedFont(fontBytes, { subset: true })
      page.setFont(customFont);
    // }

    
    return customFont;
  }
  async function sub_saveText(pdfView,item,page,customFont){   
    var scale = pdfView.currentScaleValue;    
    var x = item.aCoords.tl.x/scale,y = item.aCoords.tl.y/scale;
    var p = calculatePosition(page,x,y);
    var color = Convert.toRGB(item.fill); 
    var padding = 2;
    switch(page.getRotation().angle%360){
      case 0:
      case 360:
        p.x = p.x - item.width*Math.sin(item.angle/180),     
        p.y = p.y -  item.fontSize/scale + padding;
        break;
      case 90:
      case -270:
        p.x = p.x + item.fontSize/scale - padding;   
        p.y = p.y ;
        break;
      case 180:
      case -180:
        p.x = p.x ;     
        p.y = p.y +  item.fontSize/scale - padding;
        break;
      case 270:
      case -90:
        p.x = p.x -  item.fontSize/scale + padding;     
        p.y = p.y;
        break;
    }
    if (customFont){
      page.drawText(item.text, {
        x: p.x,
        y: p.y ,
        size: item.fontSize*item.scaleY/scale,
        font: customFont,
        color: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
        rotate : page.getRotation() ,      
        rotate:  degrees( page.getRotation().angle - item.angle)
      })
    }
    else{
      page.drawText(item.text, {
        x: p.x,
        y: p.y ,
        size: item.fontSize*item.scaleY/scale,
        color: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
        rotate : page.getRotation() ,        
        rotate:  degrees( page.getRotation().angle - item.angle)
      })
    }
    
  }

  async function saveImages(pdfDoc,img,page,scale) {
    try {      
          var pngImageBytes = null;    
          if(img._element.tagName == 'CANVAS'){
            pngImageBytes = img._element.toDataURL("image/png");  
          }
          if(img._element.tagName == 'IMG'){
            pngImageBytes = img._element.src;  
          }
          const pngImage = await pdfDoc.embedPng(pngImageBytes);   
          var x = img.aCoords.bl.x/scale,y = img.aCoords.bl.y/scale;    
          var p = calculatePosition(page,x,y);
          page.drawImage(pngImage, {
            x: p.x,
            y: p.y,
            width: img.width*img.scaleX/scale,
            height: img.height*img.scaleY/scale,
            rotate: degrees(360-img.angle),
            opacity: img.opacity,
            rotate : degrees( page.getRotation().angle - img.angle)
          })           
  
    } catch (error) {
      console.error(error);
      // alert(error); 
    }
  }

  async function savePath(item,page,scale) {
    var color,opacity;
    try {
      item.path.forEach(point => {
        for(let i = 1;i< point.length;i++){
          point[i] = point[i]/scale ;
        }
      })
  
      var pathItem = item.path;
      const svgPath = pathItem.toString();   

      // start calculate rotate
      var x = 0,y = 0;    
      var p = calculatePosition(page,x,y);
      // end

      if(item.type_path == ANNOTATION.FREE_DRAW){
        color = Convert.toRGB(item.stroke);
        opacity = parseFloat( Config.freedraw.opacity);
        page.drawSvgPath(svgPath, {
          x: p.x,    
          y: p.y,
          borderColor: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
          borderWidth: item.strokeWidth/scale,
          borderOpacity: opacity,
          opacity : opacity,
          borderDashArray: item.strokeDashArray,
          rotate: page.getRotation()
        })
      }
  
      if(item.type_path == ANNOTATION.PENCIL){
        color = Convert.toRGB(item.stroke);
        opacity = parseFloat( Config.pencil.opacity);
        page.drawSvgPath(svgPath, {
          x: p.x,    
          y: p.y,
          borderColor: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
          borderWidth: item.strokeWidth/scale,
          borderOpacity: opacity,
          borderDashArray: item.strokeDashArray,
          opacity : opacity,
          rotate: page.getRotation()
        })
      }
      if(item.type_path == ANNOTATION.DRAW_ARROW){
        color = Convert.toRGB(item.fill);
        opacity = item.opacity;
        page.drawSvgPath(svgPath, {
          x: p.x,    
          y: p.y,
          borderColor: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
          borderWidth: item.strokeWidth/scale,
          borderOpacity: opacity,
          opacity : opacity,
          color: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
        })
      }
      return true;
    } catch (error) {
      console.error(error);
      alert(error); 
      return false;
    }
  }

  //https://github.com/Hopding/pdf-lib/discussions/1233
async function saveLink(pdfDoc,item,page,scale){
  if(item.link_type.link_type == link_enum.page){
    var pageRef = pdfDoc.getPage(parseInt(item.link_type.page)-1);
    const link = page.doc.context.register(
      page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [item.aCoords.bl.x/scale,// lower left x coord
         page.getHeight()- item.aCoords.bl.y/scale ,// lower left y coord
         item.aCoords.tr.x/scale,// upper right x coord
         page.getHeight()- item.aCoords.tr.y/scale],// upper right y coord
        Border: [0, 0, 0], // [0, 0, 2] to test
        C: [0, 0, 1],
        Dest: [pageRef.ref, 'XYZ', null, null, null]
      }));
      let annots;
    try{
      annots = page.node.lookup(PDFName.of('Annots'), PDFArray);    
    } catch (error){      
      console.log('no links found:',error)
      annots = pdfDoc.context.obj([]);
      page.node.set(PDFName.of("Annots"), annots);
    }
    annots.push(link)  
    page.node.set(PDFName.of('Annots'), annots);
  }
  else{    
    var uri = item.link_type.url;    
      if(item.link_type.link_type==link_enum.phone){uri = 'tel:' + item.link_type.phone;}
      if(item.link_type.link_type==link_enum.mail){uri = 'mailto:' + item.link_type.email;}
      if(item.link_type.link_type==link_enum.url){uri =  item.link_type.url;}
    const link = page.doc.context.register(
      page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [item.aCoords.bl.x/scale,// lower left x coord
         page.getHeight()- item.aCoords.bl.y/scale ,// lower left y coord
         item.aCoords.tr.x/scale,// upper right x coord
         page.getHeight()- item.aCoords.tr.y/scale],// upper right y coord
        Border: [0, 0, 0], // [0, 0, 2] to test
        C: [0, 0, 1],
        A: {
          Type: 'Action',
          S: 'URI',
          URI: PDFString.of(uri),//URI: PDFString.of("mailto:anhvt@lotusys.vn"),PDFString.of("tel:0981560517")
        },
      }));
      
      let annots;
    try{
      annots = page.node.lookup(PDFName.of('Annots'), PDFArray);    
    } catch (error){      
      console.log('no links found:',error)
      annots = pdfDoc.context.obj([]);
      page.node.set(PDFName.of("Annots"), annots);
    }
    annots.push(link)  
    page.node.set(PDFName.of('Annots'), annots);
  }
}
async function saveRectangle(item,page,scale){
  var borderColor= Convert.toRGB(item.stroke); 
   // start calculate rotate
   var x = (item.aCoords.bl.x + item.strokeWidth/2)/scale,y = (item.aCoords.bl.y- item.strokeWidth/2)/ scale;    
   var width=item.width*item.scaleX/ scale,height=item.height*item.scaleY/ scale;
   var p = calculatePosition(page,x,y);
   // end  
  if(item.fill.trim() != ""){
    var backGroundColor= Convert.toRGB(item.fill); 
    var dash = [];
    if(item.strokeDashArray){
      dash = item.strokeDashArray
    }
    page.drawRectangle({   
      x: p.x,
      y: p.y,
      width: width,
      height: height,
      borderWidth: item.strokeWidth/scale,
      borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
      color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
      opacity: parseFloat(item.opacity),
      borderOpacity: parseFloat(item.opacity),
      borderDashArray: dash,
      rotate: degrees(page.getRotation().angle - item.angle)
    })
  }
  else{
    page.drawRectangle({   
      x: p.x,
      y: p.y,
      width: width,
      height: height,
      borderWidth: item.strokeWidth/scale,
      borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
      opacity: parseFloat(item.opacity),
      borderOpacity: parseFloat(item.opacity),
      borderDashArray: item.strokeDashArray,
      rotate: degrees(page.getRotation().angle - item.angle)
    })
  }

 
}
async function saveLine(item,page,scale){ 
  var color = item.stroke;
  color = Convert.toRGB(color);
  var linePoint1 = item.calcLinePoints();
  var point1 = fabric.util.transformPoint(new fabric.Point(linePoint1.x1,linePoint1.y1), item.calcTransformMatrix());
  var point2 = fabric.util.transformPoint(new fabric.Point(linePoint1.x2,linePoint1.y2), item.calcTransformMatrix());

  var x1 = point1.x/scale,y1 = point1.y/scale;    
  var x2 = point2.x/scale,y2 = point2.y/scale;    
  var p1 = calculatePosition(page,x1,y1);
  var p2 = calculatePosition(page,x2,y2);
  page.drawLine({
    start: { x: p1.x, y: p1.y },
    end: { x: p2.x, y: p2.y   },
    thickness: parseInt(item.strokeWidth/scale),
    color: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
    opacity: item.opacity,
    dashArray: item.strokeDashArray,
    rotate: page.getRotation(),
  })
}
async function saveEllipse(item,page,scale){
  var borderColor= Convert.toRGB(item.stroke);
  
  var x = (item.oCoords.tl.x+ item.oCoords.br.x)/(2*scale);  
  var y = (item.oCoords.tl.y+ item.oCoords.br.y)/(2*scale);  

  var p = calculatePosition(page,x,y);
  if(item.fill.trim() != ""){
    var backGroundColor= Convert.toRGB(item.fill);
    page.drawEllipse({
      x: p.x,
      y: p.y,
      xScale: item.rx*item.scaleX/scale,
      yScale: item.ry*item.scaleY/scale,
      borderWidth: item.strokeWidth/scale,
      borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
      color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
      opacity: parseFloat(item.opacity),
      borderOpacity: parseFloat(item.opacity),
      rotate: degrees(page.getRotation().angle - item.angle),
      borderDashArray: item.strokeDashArray
    })
  }
  else{
    page.drawEllipse({
      x: p.x,
      y: p.y,
      xScale: item.rx*item.scaleX/scale,
      yScale: item.ry*item.scaleY/scale,
      borderWidth: item.strokeWidth/scale,
      borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
      opacity: parseFloat(item.opacity),
      borderOpacity: parseFloat(item.opacity),
      dashArray: item.strokeDashArray,
      borderDashArray: item.strokeDashArray,
      rotate: degrees(page.getRotation().angle - item.angle)
    })
  }
  
}

async function saveEditControls(form,page,pageIndex,pdfSave){
  var currentPageDiv = document.getElementById("page-"+ (pageIndex + 1));
  var formLayer = currentPageDiv.querySelector(".formLayer");
  if(!formLayer) return;

  var lst_ff_Editable = formLayer.querySelectorAll(".ff-editable-inner ");
  if(lst_ff_Editable){
      for await(const ff_Editable of lst_ff_Editable) {   
    
    var f_input = ff_Editable.querySelector(".f-input");
    var kind = f_input.getAttribute("kind");

    switch(kind){
      case "textbox":
        var obj = {
          top:ff_Editable.offsetTop + f_input.offsetTop , 
          left: ff_Editable.offsetLeft + f_input.offsetLeft, 
          width:f_input.offsetWidth,
          height: f_input.offsetHeight,
          textColor : window.getComputedStyle(f_input).color,
          backgroundColor:window.getComputedStyle(f_input).backgroundColor,
          borderColor:window.getComputedStyle(f_input).borderColor,
          borderWidth:parseInt(window.getComputedStyle(f_input).borderWidth),
          rotate:0,
          text: f_input.value
        }

        await saveTextBox(obj,page,form,pdfSave);
        break;
      case "textarea":
          var obj = {
            top:ff_Editable.offsetTop + f_input.offsetTop , 
            left: ff_Editable.offsetLeft + f_input.offsetLeft, 
            width:f_input.offsetWidth,
            height: f_input.offsetHeight,
            textColor : window.getComputedStyle(f_input).color,
            backgroundColor:window.getComputedStyle(f_input).backgroundColor,
            borderColor:window.getComputedStyle(f_input).borderColor,
            borderWidth:parseInt(window.getComputedStyle(f_input).borderWidth),
            rotate:0,
            text: f_input.value,
            fontSize: parseInt(window.getComputedStyle(f_input).fontSize)
          }
  
          await saveArea(obj,page,form,pdfSave);
          break;
        case "radio":
          var backgroundColor = getPropertyBeforeElement(f_input,'background-color') ;

          var obj = {
            top:ff_Editable.offsetTop + f_input.offsetTop , 
            left: ff_Editable.offsetLeft + f_input.offsetLeft, 
            width:f_input.offsetWidth,
            height: f_input.offsetHeight,
            textColor : backgroundColor,
            backgroundColor:backgroundColor,
            borderColor:window.getComputedStyle(f_input).borderColor,
            borderWidth:parseInt(window.getComputedStyle(f_input).borderWidth),
            rotate:0,
            name: f_input.getAttribute("name"),
            checked: f_input.checked,
            opacity: window.getComputedStyle(f_input).opacity
          }
  
          await saveRadio(obj,page,form,pdfSave);
          break;
        case "checkbox":
          var backgroundColor = getPropertyBeforeElement(f_input,'background-color') ;
          var obj = {
            top:ff_Editable.offsetTop + f_input.offsetTop , 
            left: ff_Editable.offsetLeft + f_input.offsetLeft, 
            width:f_input.offsetWidth,
            height: f_input.offsetHeight,
            textColor : backgroundColor,
            backgroundColor:backgroundColor,
            borderColor:window.getComputedStyle(f_input).borderColor,
            borderWidth:parseInt(window.getComputedStyle(f_input).borderWidth),
            rotate:0,
            name: f_input.getAttribute("name"),
            checked: f_input.checked
          }
  
          await saveCheckBox(obj,page,form,pdfSave);
          break;
        case "dropdown":
          var obj = {
            top:ff_Editable.offsetTop + f_input.offsetTop , 
            left: ff_Editable.offsetLeft + f_input.offsetLeft, 
            width:f_input.offsetWidth,
            height: f_input.offsetHeight,
            textColor : window.getComputedStyle(f_input).color,
            backgroundColor:window.getComputedStyle(f_input).backgroundColor,
            borderColor:window.getComputedStyle(f_input).borderColor,
            borderWidth:parseInt(window.getComputedStyle(f_input).borderWidth),
            rotate:0,
            name: f_input.getAttribute("name"),
            checked: f_input.checked,
            options: f_input.querySelectorAll("option"),
            selected: f_input.querySelectorAll("option")[f_input.selectedIndex].text
          }
  
          await saveDropdown(obj,page,form,pdfSave);
          break;
    }
  };
  }

}
async function saveAnnotationFormatText(page,pageIndex,pdfView){
  var currentPageDiv = document.getElementById("page-"+ (pageIndex + 1));
  var annotationLayer = currentPageDiv.querySelector(".annotationLayer");
  if(!annotationLayer) return;
  var lst_annotations = annotationLayer.querySelectorAll(".annotation-added");
  if(lst_annotations){
    for await(const anno of lst_annotations) {      

      var obj = {
        top: anno.offsetTop , 
        left: anno.offsetLeft, 
        width:anno.offsetWidth,
        height: anno.offsetHeight,
        backgroundColor:window.getComputedStyle(anno).backgroundColor,
        opacity: anno.style.opacity,
      }

      await saveFormatText(obj,page,pdfView);
  };
  }

}
async function saveFormatText(item,page,pdfView){
  var scale = pdfView.currentScaleValue;
  var backGroundColor= RGBvalues.color(item.backgroundColor); 
  page.drawRectangle({   
    x: item.left/scale ,
    y: page.getHeight() - item.top/scale - item.height/scale,
    width: item.width/scale ,
    height: item.height/scale ,
    // rotate: degrees(360-item.angle),
    // borderWidth: item.strokeWidth/scale,
    color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
    opacity: parseFloat(item.opacity),
    // borderOpacity: parseFloat(item.opacity)
    // rotate: page.getRotation()
  }) 
}
async function saveTextBox(obj,page,form,pdfSave){
  const url = window.location.origin+'/assets/fonts/hgrskp.ttf'      
  var scale = pdfSave.pdfView.currentScaleValue; 
  var fontBytes = await fetch_data(url);
  pdfSave.pdfDoc.registerFontkit(fontkit);
  const ubuntuFont = await pdfSave.pdfDoc.embedFont(fontBytes);
  const textField = form.createTextField(random_string(16));
  textField.setText(obj.text)
  var textColor = RGBvalues.color(obj.textColor);
  var backGroundColor = RGBvalues.color(obj.backgroundColor);
  var borderColor = RGBvalues.color(obj.borderColor);

  const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
  form.updateFieldAppearances = function () {
    return rawUpdateFieldAppearances(ubuntuFont);
 };
  // textField.updateFieldAppearances(ubuntuFont);

  textField.addToPage(page, {
    x: obj.left/scale + obj.borderWidth/2*scale,
    y: page.getHeight() - obj.top/scale - obj.height/scale - 2*obj.borderWidth,
    width: obj.width/scale ,
    height: obj.height/scale + obj.borderWidth ,
    textColor: rgb(parseFloat(textColor.r)/255.0, parseFloat(textColor.g)/255.0, parseFloat(textColor.b)/255.0),
    backgroundColor: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
    borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
    borderWidth: obj.borderWidth/scale,
    rotate: degrees(obj.rotate),
    font: ubuntuFont,
  })
}
async function saveArea(obj,page,form,pdfSave){
  const url = window.location.origin+'/assets/fonts/hgrskp.ttf'      
  var scale = pdfSave.pdfView.currentScaleValue;
  // const url = window.location.origin+'/assets/fonts/times-new-roman.ttf';
  var fontBytes =  await fetch_data(url);
  pdfSave.pdfDoc.registerFontkit(fontkit);
  const ubuntuFont = await pdfSave.pdfDoc.embedFont(fontBytes);
  var idText = random_string(16);
  const textField = form.createTextField(idText);
  textField.setText(obj.text); 
  textField.enableMultiline();
  textField.enableScrolling();
  
  var textColor = RGBvalues.color(obj.textColor);
  var backGroundColor = RGBvalues.color(obj.backgroundColor);
  var borderColor = RGBvalues.color(obj.borderColor);

  const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
  form.updateFieldAppearances = function () {
    return rawUpdateFieldAppearances(ubuntuFont);
 };
  // textField.updateFieldAppearances(ubuntuFont);
  // textField.setFontSize(12); // TODO
  textField.addToPage(page, {
    x: obj.left/scale + obj.borderWidth/2*scale,
    y: page.getHeight() - obj.top/scale - obj.height/scale - 2*obj.borderWidth,
    width: obj.width/scale ,
    height: obj.height/scale + obj.borderWidth ,
    textColor: rgb(parseFloat(textColor.r)/255.0, parseFloat(textColor.g)/255.0, parseFloat(textColor.b)/255.0),
    // backgroundColor: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
    borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
    borderWidth: obj.borderWidth/scale,
    rotate: degrees(obj.rotate),
    font: ubuntuFont,
  })

}
async function saveRadio(obj,page,form,pdfSave){
  var idText = random_string(16);
  var scale = pdfSave.pdfView.currentScaleValue;
  const radioGroup = form.createRadioGroup(idText)
  var textColor = RGBvalues.color(obj.textColor);
  var backGroundColor = RGBvalues.color(obj.backgroundColor);
  var borderColor = RGBvalues.color(obj.borderColor);
  radioGroup.addOptionToPage(obj.name, page, { 
    x: obj.left/scale,
    y:page.getHeight() - obj.top/scale-obj.height/scale,
    width: obj.width/scale,
    height: obj.height/scale,
    textColor: rgb(parseFloat(textColor.r)/255.0, parseFloat(textColor.g)/255.0, parseFloat(textColor.b)/255.0),
    backgroundColor: rgb(1,1,1),  
    borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
    borderWidth: obj.borderWidth/scale,
    rotate: degrees(obj.rotate),
    opacity: obj.opacity
   
   })
   if(obj.checked){
    radioGroup.select(obj.name);

   }
}
async function saveCheckBox(obj,page,form,pdfSave){
  var scale = pdfSave.pdfView.currentScaleValue;
  var idText = random_string(16);
  const checkBox = form.createCheckBox(idText)

  var textColor = RGBvalues.color(obj.textColor);
  var backGroundColor = RGBvalues.color(obj.backgroundColor);
  var borderColor = RGBvalues.color(obj.borderColor);
  checkBox.addToPage( page, { 
    x: obj.left/scale,
    y:page.getHeight() - obj.top/scale-obj.height/scale,
    width: obj.width/scale,
    height: obj.height/scale,
    textColor: rgb(parseFloat(textColor.r)/255.0, parseFloat(textColor.g)/255.0, parseFloat(textColor.b)/255.0),
    // backgroundColor: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
    borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
    borderWidth: obj.borderWidth/scale,
    rotate: degrees(obj.rotate)   
   })
   if(obj.checked){
    checkBox.check();
   }
}
async function saveDropdown(obj,page,form,pdfSave){
  var scale = pdfSave.pdfView.currentScaleValue;
  var idText = random_string(16);
  const dropdown = form.createDropdown(idText)

  var options = obj.options;
  if(options){
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      dropdown.addOptions(element.text)      
    }
  }
  var textColor = RGBvalues.color(obj.textColor);
  var backGroundColor = RGBvalues.color(obj.backgroundColor);
  var borderColor = RGBvalues.color(obj.borderColor);
  dropdown.addToPage( page, { 
    x: obj.left/scale,
    y:page.getHeight() - obj.top/scale-obj.height/scale,
    width: obj.width/scale,
    height: obj.height/scale,
    textColor: rgb(parseFloat(textColor.r)/255.0, parseFloat(textColor.g)/255.0, parseFloat(textColor.b)/255.0),
    backgroundColor: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
    borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
    borderWidth: obj.borderWidth/scale,
    rotate: degrees(obj.rotate)   
   })
   if(obj.selected){
    dropdown.select(obj.selected)
   }
}



async function subSaveArragePages(pdfView){
  var lstStatus = [];
  var lstNumPage = [];
  var parent = document.getElementById('lstPages');
  $.each($('#lstPages .box'), function (i, row) {     
    lstStatus.push(decodeURI(this.getAttribute("blank")));
    lstNumPage.push(parseInt(this.getAttribute("num")) + 1);
  });		

  // add page
  var numberPageAdded = 0;
  for (let index2 = 0; index2 < lstNumPage.length; index2++) {
    const element = lstNumPage[index2];
    if(element > pdfView.pagesCount){
      pdfView.pdfDoc.insertPage(pdfView.pagesCount + numberPageAdded, PageSizes.A4);
      numberPageAdded++;
    }        
    
  }
  const pages = pdfView.pdfDoc.getPages();

  // remove page
  var numberPageRemoved = 0;
  for (let index1 = pdfView.pagesCount; index1 >= 1; index1--) {
    if(!lstNumPage.includes(index1)){     
      pdfView.pdfDoc.removePage(index1-1 - numberPageRemoved);
      numberPageRemoved++;
    }
  }

  reorderPages(pdfView.pdfDoc,lstNumPage, pages);
  
  // rotate
  var lstCanvas = parent.querySelectorAll('.box');
  var rotate;
  for (let index3 = 0; index3 < lstCanvas.length; index3++) {
    var rotatePage = parseInt(lstCanvas[index3].firstChild.getAttribute('rotate'));
    if(rotatePage){
      const page = pdfView.pdfDoc.getPage(index3);
      rotate = (page.getRotation().angle + parseInt(rotatePage))%360;
      page.setRotation(degrees(rotate))
    }
  }

  return await pdfView.pdfDoc.save();

 }

 function reorderPages(pdfDoc, newOrder, pages) {
  for (let currentPage = 0; currentPage < newOrder.length; currentPage++) {
    pdfDoc.removePage(currentPage);
    pdfDoc.insertPage(currentPage, pages[newOrder[currentPage]-1]);
  }
};

async function saveRotateFile(pdfDoc,pages_in_view){
  var page,rotatePage; 
  for (let index = 0; index < pages_in_view.length; index++) {
    const element = pages_in_view[index];
    if(element.viewRotate != 0)
    {
      page = pdfDoc.getPage(index);
      rotatePage = (element.viewRotate + page.getRotation().angle)%360;
      page.setRotation(degrees(rotatePage))
    }
  }
  await pdfDoc.save();
}

async function subSaveMergePages(pdfView, lstPathLeft, lstNumPage, lstPathRight, lstPdfDocs){
  //add page
  var numberPageAdded = 0;
  for (let index1 = 0; index1 < lstNumPage.length; index1++) {
    for (let index2 = 1; index2 < lstPathLeft.length; index2++) {
      if (lstPathRight[index1] == lstPathLeft[index2]) {
        var [newPage] = await pdfView.pdfDoc.copyPages(lstPdfDocs[index2], [lstNumPage[index1] - 1]);
        pdfView.pdfDoc.insertPage(pdfView.pagesCount + numberPageAdded, newPage);
        numberPageAdded++;
        lstNumPage[index1] = pdfView.pagesCount + numberPageAdded;
      }
    }
  }

  const pages = pdfView.pdfDoc.getPages();

  // remove page
  var numberPageRemoved = 0;
  for (let index = 0; index < pdfView.pagesCount; index++) {   
    if (!lstNumPage.includes(index + 1)){     
      pdfView.pdfDoc.removePage(index - numberPageRemoved);
      numberPageRemoved++;
    }
  }

  // reorder page
  for (let index1 = 0; index1 < lstNumPage.length; index1++) {
      if(pdfView.pdfDoc.getPageCount()  >  index1 ){
        pdfView.pdfDoc.removePage(index1);
      }     
      pdfView.pdfDoc.insertPage(index1, pages[lstNumPage[index1]-1]);
  }

  

  return await pdfView.pdfDoc.save();
}

function calculatePosition(page,x,y){
  var out_x = x,out_y = y;
  switch(page.getRotation().angle%360){
    case 0:
    case 360:
      out_x =  x;
      out_y =  page.getHeight() - y;
      break;
    case 90:
    case -270:
      out_x =  y;
      out_y =  x;
      break;
    case 180:
    case -180:
      out_x =  page.getWidth() - x;
      out_y =   y;
      break;
    case 270:
    case -90:
      out_x = page.getWidth() -  y;
      out_y =  page.getHeight() - x;
      break;
  }

  var artBoxX = page.getArtBox().x;
  var artBoxY = page.getArtBox().y;
  out_x = Math.abs(Math.round(out_x))+artBoxX;
  out_y = Math.abs(Math.round(out_y))+artBoxY;
  return {x: out_x,y: out_y};
}

