$(document).on('turbo:load', function() {
  var quill;
  var url;

  $("body").delegate('#issue_info_offcanvas .select2-selection__rendered', 'click', function(e) {
    $('.select2-results__option').hover(function () {
      $(this).removeAttr('title');
    });
  })

  if($("#show_issue_action").length > 0){
    $("#issue_info_offcanvas").css("width", "calc(100% - 600px)");
    $("#issue_info_offcanvas").addClass('show');
    initialQuill("#quill_comment_input", "#moot_content");
    $(".select_assign_issue").each(function(){
      $(this).select2({
        dropdownParent: $(".offcanvas-content"),
        templateResult: formatState,
        templateSelection: formatSection,
        dropdownAutoWidth : true,
      }).on("change", function(e){
        assignee_id = this.value;
        $.ajax({
          type: "PUT",
          url: $(".issue_title").data('url'),
          dataType: "script",
          data: {
            assignee_id: assignee_id,
            sub_issue_id: $(this).data('item-id')
          },
          success: function (data, textStatus, jqXHR) {
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
      })
    });
    settingDropZone('IssueDropZone');
  }

  var myOffcanvas = document.getElementById('issue_info_offcanvas')
  if (myOffcanvas) {
    myOffcanvas.addEventListener('hidden.bs.offcanvas', function () {
      window.history.pushState("","", $("#header_master_plan").find("a.active").attr("href"));
    })
  }

  window.addEventListener("popstate", (event) => {
    let list_url =  /\/master_plans\/[0-9][0-9]\/issues/,
        url = window.location.pathname;
    if(url.match(list_url)){
      window.location.reload();
    }
  });

  $("body").delegate('.issue_table tbody tr, .show_sub_issue', 'click', function(e) {
    $("#issue_info_offcanvas").css("width", "calc(100% - 600px)");
    url = $(this).data('url')
    id = url.substring(url.lastIndexOf('/') + 1);
    if ($(e.target).is('#checkbox-issue')) {
      e.stopPropagation();
    return;
    }

    window.history.pushState("","", url );

    $.ajax ({
      type: 'GET',
      url: url,
      dataType: 'script',
      success: function(data, textStatus, jqXHR){
        $(".select_assign_issue").each(function(){
          $(this).select2({
            dropdownParent: $(".offcanvas-content"),
            templateResult: formatState,
            templateSelection: formatSection,
            dropdownAutoWidth : true,
          }).on("change", function(e){
            assignee_id = this.value;
            $.ajax({
              type: "PUT",
              url: $(".issue_title").data('url'),
              dataType: "script",
              data: {
                assignee_id: assignee_id,
                sub_issue_id: $(this).data('item-id')
              },
              success: function (data, textStatus, jqXHR) {
              },
              error: function (jqXHR, textStatus, errorThrown) {
                console.log(jqXHR.responseText)
              }
            })
          })
        });
        settingDropZone('IssueDropZone');
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $('body').delegate('#checkbox-issue', 'click', function (e) {
    if ($(this).parent().hasClass("lowest-level-issue")) {
      $.ajax({
        type: "PUT",
        url: $(this).data('url'),
        dataType: "script",
        data: {
          complete: this.checked
        },
        success: function (data, textStatus, jqXHR) {

        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
    }
    else {
      let update_progress = $(this).data('url');
      let isChecked = $(this).prop("checked");
      $(this).prop("checked", !isChecked);
      let is_done;
      if (isChecked){
        is_done = true
        $("#modal_confirm_done_issue .modal-body p").text(I18n.t('issues.board.complete_parent_issue'));
        $(".update_all_subtask").click();
      }
      else {
        is_done = false
        $("#modal_confirm_done_issue .modal-body p").text(I18n.t('issues.board.reset_parent_issue'));
        $(".update_all_subtask").click();
      }

      $("body").delegate('.update_done_issue', 'click', function() {
        $.ajax({
          type: "PUT",
          url: update_progress,
          dataType: "script",
          data: {
            complete: is_done
          },
          success: function (data, textStatus, jqXHR) {

          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
      });
    }
  });

  $("body").delegate('.description-input', 'click', function() {
    $(this).addClass("d-none");
    $(this.parentElement).find(".form-issue-description").removeClass("d-none");
    if ( $("#issue-description-editor").length > 0) {
      quill = new Quill('#issue-description-editor', {
        theme: 'snow',
        modules: {
          'toolbar': [
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }]
          ]
        },
      });
      const length = quill.getLength();
      quill.setSelection(length, length);
      quill.focus();

      quill.keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, context) => {
          $("#des_issue").val(quill.root.innerHTML);
          var description = $("#des_issue").val()
          $.ajax({
            type: "PUT",
            url: $(".issue_title").data('url'),
            dataType: "script",
            data: {
              description: description
            },
            success: function (data, textStatus, jqXHR) {

            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR.responseText)
            }
          })
        }
      });
    }
  });

  $('body').delegate('.delete-issue-job, .issue_title, .show_sub_issue', 'mouseenter', function (e) {
    $(this.parentElement.parentElement).find("input").first().addClass("bg-light")
  });

  $('body').delegate('.delete-issue-job, .issue_title, .show_sub_issue', 'mouseleave', function (e) {
    $(this.parentElement.parentElement).find("input").removeClass("bg-light")
  });

  $("body").delegate('.sub_issue', 'keydown', function(event) {
    var title = $(".sub_issue").val()
    if (event.keyCode == 13 && title.trim() != '') {
      if ($(".show_actual_time_log_work_issue").children().first().data('log-work') == false) {
        sendIssueData(title);
      }
      else {
        $.ajax({
          type: "GET",
          url: "/log_works/confirm_delete_log_work",
          dataType: "script",
          data: {
            master_plan_id: $("#header_master_plan").data('id'),
          },
          success: function (data, textStatus, jqXHR) {
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
      }

    }
  });

  $("body").delegate('.delete_log_work_issue_and_create_sub_issue', 'click', function(event) {
    var title = $(".sub_issue").val()
    sendIssueData(title);
  });

  var ajaxInProgress = false;
  $("body").delegate('.issue_title', 'blur keydown', function(event) {
    var title = $(this).val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
      return;
    }
    if (ajaxInProgress) {
      return;
    }
    ajaxInProgress = true;
    $.ajax({
      type: "PUT",
      url: $(this).data('url'),
      dataType: "script",
      data: {
        title: title,
        id: $(this).data('id'),
        parent_id: true
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
      complete: function() {
        // Reset the flag to false when the AJAX call is complete
        ajaxInProgress = false;
      }
    })
  });

  $("body").delegate('.sub_issue_title', 'blur keydown', function(event) {
    var title = $(this).val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
      return;
    }
    if (ajaxInProgress) {
      return;
    }
    ajaxInProgress = true;
    $.ajax({
      type: "PUT",
      url: $(this).data('url'),
      dataType: "script",
      data: {
        title: title,
        id: $(this).data('id')
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
      complete: function() {
        // Reset the flag to false when the AJAX call is complete
        ajaxInProgress = false;
      }
    })
  });

  $("body").delegate('#issue_info_offcanvas .form-collapse-comment', 'click', function() {
    $(this).addClass("d-none");
    $("#issue_info_offcanvas .offcanvas-body").removeClass("pe-0");
    $("#issue_info_offcanvas").css("width", "calc(100% - 300px)");
    $("#issue_info_offcanvas .comment").removeClass("d-none");
    $("#issue_info_offcanvas .content").css("width", "66.66%");
  });

  $("body").delegate('#issue_info_offcanvas .collapse-comment', 'click', function() {
    $("#issue_info_offcanvas .total_comment").text($(this).parent().find('li').first().data("size"));
    $("#issue_info_offcanvas .comment").addClass("d-none");
    $("#issue_info_offcanvas .form-collapse-comment").removeClass("d-none");
    $("#issue_info_offcanvas .content").css("width", "100%");
    $("#issue_info_offcanvas").css("width", "calc(100% - 600px)");
    $("#issue_info_offcanvas .offcanvas-body").addClass("pe-0");
  });


  $(document).delegate('#slider_range', 'change', function() {
    var newValue = $(this).val();
    // Gửi AJAX request để cập nhật tiến độ
    $.ajax({
      url: $(this).data("url"),
      type: 'PUT',
      dataType: 'script',
      data: {
        range_value: newValue },
      success: function(data) {
        // Xử lý kết quả trả về (nếu cần)
      },
      error: function(xhr, status, error) {
        console.error('Error:', error);
      }
    });
  });

  $(document).delegate('#slider_range', 'input', function() {
    var newValue = $(this).val();
    $(this).parent().parent().parent().find(".slider_value").text(newValue + "%")
  });

  $('body').delegate('.add_task_in_task_list', 'click', function (e) {
    $(this).parent().addClass("d-none");
    $(this).parent().parent().find(".input_add_task_in_task_list").removeClass("d-none").focus();
	});

  $('body').delegate('.input_add_task_in_task_list', 'keydown', function () {
    let title =  $(this).val();
    if (event.keyCode == 13 && title.trim() != '') {
      $(this).val("");
      $.ajax({
        type: "POST",
        url: "/master_plans/" + $("#header_master_plan").data('id') + "/issues",
        dataType: "script",
        data: {
          issue: {
            assignee_id: "",
            title: title
          },
          screen: "list"
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
	});

  function formatState (state) {
    if (state.id == '') {
      let src =  state.element.getAttribute('data-url');
      let $img = $(
        '<span><img src="' + src + '"class="rounded-circle" style="width:20px; height:20px" /> ' +  state.text +'</span>');
      return $img;
    }

    if (state.id) {
      let src =  state.element.getAttribute('data-url');
      let $state = $(
        '<span><img src="' + src + '"class="rounded-circle" style="width:20px; height:20px" /> ' + state.text + '</span>');
      return $state;
    }
  };

  function formatSection (state) {
    if (state.id == '') {
      let src_img_plus =  state.element.getAttribute('data-no-assignee');
      let $img = $(
        '<span class ="img_assingee"><img src="' + src_img_plus + '"class="rounded-circle" style="width:20px; height:20px" /> ' +  '</span>');
      return $img;
    }

    let src =  state.element.getAttribute('data-url');
    let $state = $(
      '<span><img src="' + src + '"class="rounded-circle" style="width:20px; height:20px" /> </span>');
    return $state;
  };

  function sendIssueData(title) {
    $.ajax({
      type: "POST",
      url:"/master_plans/" + $("#detail_master_plan").data('id') + "/issues",
      dataType: "script",
      data: {
        issue: { title: title, assignee_id: "" },
        parent_id: $(".sub_issue").data('id'),
        screen: $(".sub_issue").data('screen'),
      },
      success: function (data, textStatus, jqXHR) {
        $(".select_assign_issue").each(function(){
          $(this).select2({
            dropdownParent: $(".offcanvas-content"),
            templateResult: formatState,
            templateSelection: formatSection,
            dropdownAutoWidth : true,
          }).on("change", function(e){
            assignee_id = this.value;
            $.ajax({
              type: "PUT",
              url: $(this).parent().data('url'),
              dataType: "script",
              data: {
                assignee_id: assignee_id,
                sub_issue_id: $(this).data('item-id')
              },
              success: function (data, textStatus, jqXHR) {
              },
              error: function (jqXHR, textStatus, errorThrown) {
                console.log(jqXHR.responseText)
              }
            })
          })
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
    })
  }

  function settingDropZone(dropZoneId){
    $(function() {
      var dropzone= document.getElementById(dropZoneId);
      if(dropzone){
        $(`#${dropZoneId}`).dropzone({
          url: "/master_plans/"+ $("#header_master_plan").data("id") +"/issues/add_file_in_issue",
          maxFilesize: 16,
          maxFiles: 100,
          acceptedFiles: getListAcceptFileType("default"),
          disablePreviews: true,
          clickable: true,
          headers: { 'Accept': 'text/javascript' },
          success: function (response) {
            eval(response.xhr.response);
          },
          init: function() {
            this.on("error", function(file, message, xhr) {
              if (xhr == null){
                this.removeFile(file);
              } 
              alert(file.name + " upload error : "+ message);   
            });
            this.on("addedfile", function(file) {
              document.getElementById('upload_file_issue').style.display="block";
              dropzone.style.display="block";
              dropzone.style.minHeight="min-content";
            });
            this.on("sending", function(file, xhr, formData) {
              var attachmentFormData = new FormData();
              attachmentFormData.append("attachment[attachment]", file);
              attachmentFormData.append("attachment[attachment_type]", file.type);
              attachmentFormData.append("attachment[category_attachment_id]", $(".issue_job").data("id"));
              attachmentFormData.append("attachment[category_attachment_type]", "Issue");
              attachmentFormData.append("attachment[master_plan_id]", $("#header_master_plan").data("id"));

              attachmentFormData.forEach((value, key) => {
                formData.append(key, value);
              });
              formData.append('issue_id', $(".issue_job").data("id"));
            });
          }
        });
      }
    });
  }
});
