// class create Container contain content of file
import { PDF } from '../../../libs/pdf-editor/define/PDF';
import { url_helper } from '../../supports/url_helper';
import { annotation_helper } from '../../../libs/pdf-editor/common/annotation_helper';
import { load_element_annotation_thumbnail } from '../common/load_annotation_thumbnail';
import { selected_page } from '../common/selected_page';

export class PDFMerge {
  file
  pdf

  constructor(url) {
    this.url = url;
    this.file = new PDF();
  }

  async open() {
    this.file.url = this.url;

    if (el('slug')) {
      this.file.id = el('slug').value;
    }
    var result = await setDataByURL(this);
    if (!result) return;

    load(this);
    this.file.loading = false;
  }
}

function load(mergePage) {
  var loadingTask = pdfjsLib.getDocument({
    data: mergePage.file.bytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });

  loadingTask.promise.then(function (pdf) {
    mergePage.pdf = pdf;
    createComponents(mergePage);
  })
}

async function setDataByURL(mergePage) {
  try {
    var bytes = await getPdfBytes(mergePage.file.url);
    mergePage.file.bytes = bytes;
    return true;
  }
  catch (error) {
    console.log(error);
    alert(error.message);
    return false;
  }
}

async function getPdfBytes(url) {
  var bytes = await fetch_data(url);
  return bytes;
}

async function getPages(pdf) {
  const pageNumbers = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
  const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));
  return await Promise.all(promises);
}

async function createComponents(mergePage) {
  var init_page = get_page_number_init();

  if (mergePage.app_type == 0) {
    init_page = 1;
  }
  mergePage.file.pages = await getPages(mergePage.pdf);
  definePropertiesPage(mergePage.file.pages);

  mergePage.file.numPages = mergePage.pdf.numPages;
  loadMergePage(mergePage);
}

function get_page_number_init() {
  var init_page = 1;
  var page = url_helper.get_value_params_from_URL('page');
  if (page) {
    init_page = parseInt(page);
  }
  return init_page;
}

async function loadMergePage(mergePage) {
  var page, div_page, canvas;
  var array_fabric_canvas = []
  var i;
  var parent = document.getElementById("lstRightItems");
  parent.replaceChildren();
  var rowImage = document.createElement("div");
  rowImage.className = "row lstPage d-flex align-items-center";
  rowImage.addEventListener('drop', drop);
  rowImage.addEventListener('dragover', allowDrop);

  createOption(mergePage.file.pages.length);

  for (i = 0; i < mergePage.file.pages.length; i++) {
    page = mergePage.file.pages[i];
    div_page = create_merge_page(page);
    rowImage.appendChild(div_page);
    canvas = renderMergePage(page, mergePage, div_page);
    array_fabric_canvas.push(canvas);
  }
  parent.appendChild(rowImage, parent.id);
  return array_fabric_canvas;
}

function create_merge_page(page) {
  var divPage, width, height;
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;
  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }
  width = viewport.width * scale_thum;
  height = viewport.height * scale_thum;

  divPage = document.createElement("li");
  divPage.setAttribute("id", "thumbnail-frame-" + (page._pageIndex));
  divPage.setAttribute("class", "thumbnail-frame d-flex btn-fit-content m-1 p-0 droptarget");
  divPage.setAttribute("data-page-number", page._pageIndex);
  divPage.setAttribute("aria-label", "Page " + (page.pageNumber));
  divPage.setAttribute('draggable', true);
  divPage.addEventListener('dragstart', drag);
  divPage.addEventListener('dragover', function (event) {
    if (event.target.parentElement.className.includes("droptarget")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.style.border = "2px dotted #369FF9";
    }
    if (event.target.parentElement.className.includes("pdf-thump")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.parentElement.style.border = "2px dotted #369FF9";
    }
  });
  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (page._pageIndex + 1);
  divPage.appendChild(span);

  return divPage;
}
function renderMergePage(page, mergePage, div_page) {
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;
  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }
  viewport = page.getViewport({ scale: scale_thum, rotation: rotate });
  div_page.replaceChildren();
  div_page.setAttribute("scale", scale_thum);
  var canvas = create_canvas_page(viewport, mergePage, div_page, page, scale_thum);
  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (page._pageIndex + 1);
  div_page.appendChild(span);

  return canvas;
}

function create_canvas_page(viewport, mergePage, div_page, page, scale_thum) {
  var width = parseFloat(viewport.width);
  var height = parseFloat(viewport.height);
  var canvas = document.createElement("canvas");
  canvas.style.width = width + "px";
  canvas.style.height = height + "px";

  var currentPathFile = document.getElementById('url_file').value;
  var divcanvasWrapper = document.createElement("div");
  divcanvasWrapper.setAttribute("class", "canvasWrapper");
  divcanvasWrapper.id = "pdf_thum" + page._pageIndex;
  divcanvasWrapper.className = "pdf-thump box";
  divcanvasWrapper.setAttribute("selected", false);
  divcanvasWrapper.setAttribute("blank", false);
  divcanvasWrapper.setAttribute("data-path", currentPathFile);
  divcanvasWrapper.setAttribute("num", page._pageIndex);
  divcanvasWrapper.addEventListener('contextmenu', event => event.preventDefault());
  divcanvasWrapper.style.width = width + "px";
  divcanvasWrapper.style.height = height + "px";
  canvas.addEventListener('click', selected_page, false);

  divcanvasWrapper.appendChild(canvas);
  div_page.appendChild(divcanvasWrapper);
  canvas.height = height;
  canvas.width = width;

  var canvasannotation = createElementCanvasannotation(canvas);
  div_page.appendChild(canvasannotation);

  var annotationLayer = document.createElement("div");
  annotationLayer.setAttribute("class", "annotationLayer");
  div_page.appendChild(annotationLayer);
  const renderContext = {
    annotationMode: 2,
    background: 'rgba(0,0,0,0)',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  var renderTask = page.render(renderContext);
  var fabricCanvas = init_merge_page(canvasannotation, mergePage, page._pageIndex, div_page, scale_thum);
  renderTask.promise.then(function () {
    page.getTextContent().then(function (textContent) {
      const eventBus = new pdfjsViewer.EventBus();
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      page.getAnnotations().then(function (annotationsData) {
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService,
          textLayerMode: 2
        });
      })
    });
  });
  return fabricCanvas;
}

function init_merge_page(input_canvas, mergePage, page_index, div_page, scale) {
  var canvas = new fabric.Canvas(input_canvas, {
    preserveObjectStacking: true
  });

  load_element_annotation_thumbnail(canvas, mergePage, page_index, div_page, scale);
  canvas.forEachObject(object => {
    object.selectable = false;
    object.evented = false;
  });
  return canvas;
}

function createElementCanvasannotation(canvas) {
  var canvasannotation = canvas.cloneNode();
  canvasannotation.width = canvas.width;
  canvasannotation.height = canvas.height;
  return canvasannotation;
}

function removeOptions(selectElement) {
  var i, L = selectElement.options.length - 1;
  for (i = L; i >= 0; i--) {
    selectElement.remove(i);
  }
}

function createOption(totalPage) {
  var select = document.getElementById('lstPosition');
  removeOptions(select);
  if (totalPage == 0) return;
  for (let index = 0; index <= totalPage; index++) {
    if (index == totalPage) {
      select.append(new Option('End'));
    }
    else {
      select.append(new Option(index));
    }
  }
  select.options[totalPage].setAttribute('selected', 'selected');
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  var thisdiv = ev.target;
  if (thisdiv.tagName == 'CANVAS' && event.target.parentElement.className.includes("pdf-thump")) {
    thisdiv = thisdiv.closest('.thumbnail-frame');
  }
  if (thisdiv.className.includes("droptarget")) {
    $(document.getElementById('lstRightItems').querySelector('#' + data)).insertBefore(thisdiv);
  }

  $(".droptarget").css("border", "white");
}

function allowDrop(ev) {
  ev.preventDefault();
}

function definePropertiesPage(pages) {
  var rotate_element, page_rotate = 0;

  for (let index = 0; index < pages.length; index++) {
    const element = pages[index];
    page_rotate = 0
    rotate_element = el('rotation-page-' + (index + 1))
    if (rotate_element && rotate_element.value) {
      page_rotate = parseInt(rotate_element.value);
    }
    Object.defineProperties(element, {
      viewRotate: {
        value: page_rotate,
        writable: true
      }
    })

    var list_annos = annotation_helper.get_annotations_by_index(index);
    Object.defineProperties(element, {
      annotation: {
        value: list_annos,
        writable: true
      }
    })
  }
}
