import {savePermision} from '../packs/permission';
import { download_helper } from './supports/download_helper';
import { url_helper } from './supports/url_helper';
import { Tree } from './jstree/tree';
$(document).on('turbo:load', function () {
  if ($("#tree_view_data").length){    
    LoadDataFolder();
  }

  var toggler = document.getElementsByClassName("caret");
  var i;

  for (i = 0; i < toggler.length; i++) {
    toggler[i].addEventListener("click", function () {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    });
  }

  var tblFolderHistories = document.getElementsByClassName("tblFolderHistories");
  if (tblFolderHistories.length > 0) {
    window.$('.tblFolderHistories').DataTable({
      'stateSave': true,
      'destroy': true,
      'columnDefs': [
        { "width": "10%", "targets": 0 },
        { "width": "10%", "targets": 1 },
        { "width": "25%", "targets": 2 },
        { "width": "25%", "targets": 3 },
        { "width": "30%", "targets": 4 },
      ],
      // 'order': [1, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      //add horizontal scrollbar code
      fnInitComplete: function () {
        // Enable TFOOT scoll bars
        $('.dataTables_scrollFoot').css('overflow', 'auto');

        //  Sync TFOOT scrolling with TBODY
        $('.dataTables_scrollFoot').on('scroll', function () {
          $('.dataTables_scrollBody').scrollLeft($(this).scrollLeft());
        });
      },
      scrollX: true,
      paging: true,
      fixedColumns: {
        leftColumns: 1
      },
      //end horizontal scrollbar code
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  }

  window.$("#modal-window-multiple-file-relocation-data-folder").on("shown.bs.modal", function () {
    initTreeDataFolder('tree_relocation_folder',show_path_relocation)
  })

  window.$("#modal-window-relocation-data-folder").on("shown.bs.modal", function () {
    initTreeDataFolder('tree_relocation_folder',show_path_relocation);
    $('#tree_relocation_folder').on('loaded.jstree', function(e,data) {      
      var selectedNode = $("#tree_relocation_folder").jstree("get_selected");
      var childrens = $("#tree_relocation_folder").jstree("get_children_dom",selectedNode);
      if(childrens.length>0) 
      {
        for(var i=0;i<childrens.length;i++)
          {
            $("#tree_relocation_folder").jstree("delete_node",childrens[i])
          }
        }
      })    
  })

  $('body').delegate('#share_email_box', 'keydown', function (e) {
    my_custom_keypress(e);
  });

  $('body').delegate('#share_email_box', 'keyup', function (e) {
    delete_email_text(e);
  });

  $(document).mouseup(function (e) {
    var container = $("#share_email_box");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      create_email(e);
    }
  });
  
  $('body').delegate('#btnShareFolder', 'click', function (e) {
    var error_email = document.getElementsByClassName("not-valid-email");
    if (error_email.length == 0) {
      var email_string = document.getElementsByClassName("email-span");
      if (email_string.length != 0) {
        email_string.forEach(joinEmail);
      }
    }
    else {
      var text = $('#share_email_string');
      text.val("error");
    }
    var form = document.querySelector('#form-share-email');
    form.dispatchEvent(new Event('submit', { bubbles: true }));
  });

  $('body').delegate('#btnSaveEditPermission', 'click', function (e) {
    // lay index row
    // var rowIdx = this.parentElement.parentElement.rowIndex;
    // var table = document.getElementById('tbl_user_share');
    // var id = table.rows[rowIdx].cells[0].innerHTML;
    var email =document.getElementsByClassName('email-span');
    if (email.length > 0){
    }
    else{
    var arrFolderPermission = [];
    var item = [];
    $('#tbl_user_share > tbody > tr').each(function() {
      if(this.rowIndex>1)
      {
        item = [$('#tbl_user_share tr')[this.rowIndex].cells[0].innerHTML,$('#tbl_user_share tr')[this.rowIndex].querySelectorAll('select')[0].value];
      }
      arrFolderPermission.push(item);
   });
    $.ajax({
      type: "POST",
      url: "change_permission",
      dataType: "script",
      data: { 
        arrpermission: arrFolderPermission
      },
      success: function (data, textStatus, jqXHR) {
        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  }
  });


  $("body").delegate('#add-decentralized-department-data-folder', 'click', function () {
    addDecentralizeDepartment();
  });

  $("body").delegate('#remove-decentralized-department-data-folder', 'click', function () {
    removeDecentralizeDepartment();
  });

  $("body").delegate('#btnSubmitDepartmentDataFolder', 'click', function () {
    var id = $(".decentralize-folder-id")[0].textContent;
    savePermision("data_folders/update_decentralize",id,"data_folder");
  });

  $("body").delegate('#btnShareFolderOuter', 'click', function () {
    var error_email = document.getElementsByClassName("not-valid-email");
    if(error_email.length == 0) {
      var email_string = document.getElementsByClassName("email-span");
      if(email_string.length != 0) {
        email_string.forEach(joinEmail);
      }
    }
    else {
      var text = $('#email_string');
      text.val("error"); 
    }

    var form = document.querySelector('.new_permission_folder_share_outer');
    
    form.dispatchEvent(new Event('submit', {bubbles: true}));
  });

  $("body").delegate('#btnUrl', 'click', function () {
    // $.ajax({
    //   type: 'GET',
    //   url: "/copy_url",
    //   dataType: 'script',
    //   data: {},
    //   success: function(data, textStatus, jqXHR){
    //   },
    //   error:function(jqXHR, textStatus, errorThrown){
    //     console.log("AJAX Error")
    //   }
    // });
   

    var copyText = document.getElementById("url_page");
    if(!copyText.value){
      var url = window.location.href;
      copyText.value = url;
    }
    
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    copyToClipboard(copyText.value);
  });

  $("body").delegate('#btnRelocationDataFolder', 'click', function () {
    var node_selected = $("#tree_relocation_folder").jstree("get_selected");
    if(node_selected.length > 0 ){
      var parent_folder_id = parseInt(node_selected[0]);  
      var folder_id = parseInt(el("tree_relocation_folder").getAttribute('selected'));    
      if(parent_folder_id != folder_id){
        $.ajax({
          type: 'POST',
          url: window.origin +  "/data_folders/update_location",
          dataType: 'script',
          data: {
            id: folder_id, 
            parent_folder_id: parent_folder_id
          },
          success: function (data, textStatus, jqXHR) {
            window.$("#modal-window-relocation").modal('hide');         
      
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        });
      }      
    }
  })
});

function openParentNodeFunction(param) {
  var current_folder = document.getElementById(param);
  var parent_li = current_folder.parentNode;
  var parent_ul = parent_li.parentNode;
  if (parent_ul.className == "myUL") {
    return;
  }
  var parent_node = parent_ul.parentNode;
  var parent_caret = null;
  var parent_nested = null;
  var current_node = null;
  for (var i = 0; i < parent_node.childNodes.length; i++) {
    if (parent_node.childNodes[i].className == "caret") {
      parent_caret = parent_node.childNodes[i];
      if (parent_node.childNodes[i].className != "caret-down") {
        $(parent_caret).addClass("caret-down");
      }
    }
    if (parent_node.childNodes[i].className == "nested") {
      parent_nested = parent_node.childNodes[i];
      if (parent_node.childNodes[i].className != "active") {
        $(parent_nested).addClass("active");
      }
    }
    if (parent_node.childNodes[i].className == "choose-folder") {
      openParentNodeFunction($(parent_node.childNodes[i]).attr('id'));
    }
  }
}

function setFolerNameAndId(name, id) {
  var folder_name = $("#current-folder-name");
  var folder_id = $("#current-folder-id");
  var old_id = $("#current-folder-id").val();
  folder_name.text(name);
  folder_id.val(id);
  // var href = $("#create-file-btn")[0].href.split("=", 1);
  // href = href + "=" + id;
  // $("#create-file-btn").attr("href", href);

  if ($("#admin-decentralize-link").length > 0) {
    var admin_decentralize_href = $("#admin-decentralize-link")[0].href.replace("/folders/" + old_id + "/admin_decentralize", "/folders/" + id + "/admin_decentralize");
    $("#admin-decentralize-link").attr("href", admin_decentralize_href);
  }

  if ($("#decentralize-link").length > 0) {
    var decentralize_href = $("#decentralize-link")[0].href.replace("/folders/" + old_id + "/decentralize", "/folders/" + id + "/decentralize");
    $("#decentralize-link").attr("href", decentralize_href);
  }
}

function addDecentralizeDepartment() {
  var switch_department_item = $("#not-decentralized-department-data-folder").val();
  if (switch_department_item.length > 0) {
    var selected = document.getElementById('tbl-lst-department-selected-data-folder');
    var current_department_item = []
    if (selected.getElementsByTagName("tr").length > 0) {
      for (let tr of selected.getElementsByTagName("tr")) {
        var row_id = tr.getElementsByTagName("td")[0].textContent;
        var row_name = tr.getElementsByTagName("td")[1].textContent;
        var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
        var row = [row_id, row_name, row_is_read_only]
        current_department_item.push(row);
      }
    }
    else {
      current_department_item.push(false);
    }

    // Lay id dang dc chon
    // for (let item of $("#not-decentralized-group").children(':selected')) {
    //   switch_group_item.push([item.value, item.text]);
    // }
    var id = $(".decentralize-folder-id")[0].textContent;
    // dung ajax gui id len controller, controller se lay object tơơng ung voi id
    $.ajax({
      type: 'GET',
      url: "data_folders/add_department_decentralize",
      dataType: 'script',
      data: {
        folder_id: id,
        current_department: current_department_item,
        switch_department: switch_department_item
      },
      success: function (data, textStatus, jqXHR) {
        
        // $(select_tag).find('option:selected').detach().prop("selected", false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }
  // tao js.erb 
}

var SPLIT_KEY_1 = 32;
var SPLIT_KEY_2 = 186;
var SPLIT_KEY_3 = 188;
var ENTER = 13; //This is the js keycode for ENTER
var key;
function my_custom_keypress(e) {
  key = e.which;
  if (key == ENTER || key == SPLIT_KEY_1 || key == SPLIT_KEY_2 || key == SPLIT_KEY_3) {
    create_an_item_email();
  }
}

function create_an_item_email(){
  var emailElem =document.getElementById('recipient_share_mail');

  if(!emailElem) return;
  var email = emailElem.value;
  
  if(email){
    email = email.trim();
    var check_duplicate = false;
    document.querySelectorAll('#share_email_box > .email-span').forEach(emails => { 
      if (emails.firstChild.textContent === email) {
        check_duplicate = true;
        return;
      }
    });

    if (!check_duplicate) {
      if (validate(email)) {
        var email_span = "<span class='email-span px-1 py-0 my-1 d-flex me-1'>" + email + "<button type='button' class='btn shadow-none btn-remove-email p-0 ps-1 align-self-center'>&times;</button>" +
          "</span>";
      }
      else {
        var email_span = "<span class='email-span not-valid-email px-1 py-0 bg-danger text-white my-1 me-1 d-flex'>" + email + "<button type='button' class='btn shadow-none btn-remove-email p-0 text-white ps-1 align-self-center'>&times;</button>" +
          "</span>";
      }
      $(email_span).insertBefore("#recipient_share_mail");
      
      var btnClose = document.getElementsByClassName("btn-remove-email");
      for (var i = 0; i < btnClose.length; i++) {
        btnClose[i].addEventListener('click', function (e) {
          $(this).parent().remove();
        });
      }
    }
    $("#recipient_share_mail").val("");
  }
}

function joinEmail(item, index) {
  var text = $('#email_string');
  if (text.val() == "error") {
    text.val("");
  }
  text.val(text.val() + item.textContent.slice(0, -1) + " ");
}

function create_email(e) {
  create_an_item_email();  
}

function delete_email_text(e) {
  key = e.which;
  if (key == SPLIT_KEY_1 || key == SPLIT_KEY_2 || key == SPLIT_KEY_3) {
    $("#recipient_share_mail").val("");
  }
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validate(email) {
  if (validateEmail(email)) {
    return true;
  }
  else {
    return false;
  }
}

function removeDecentralizeDepartment() {
  var check_action = $(".selected-row").length
  if (check_action > 0) {
    var selected = $('.name_permission').not(".selected-row");
    var current_department_item = []
    if (selected.length > 0) {
      for (let td of selected) {
        var tr = td.parentNode
        var row_id = tr.getElementsByTagName("td")[0].textContent;
        var row_name = tr.getElementsByTagName("td")[1].textContent;
        var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
        var row = [row_id, row_name, row_is_read_only]
        current_department_item.push(row);
      }
    }
    else {
      current_department_item.push(false);
    }


    // Lay id dang dc chon
    // var itemtable = document.getElementById('tbl-lst-group-selected');
    // var id = $("#tbl-lst-group-selected").val();
    $.ajax({
      type: 'GET',
      url: "data_folders/remove_department_decentralize",
      dataType: 'script',
      data: {
        current_department: current_department_item
      },
      success: function (data, textStatus, jqXHR) {

        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }
}

function formatTableDataFolderFile() {
  var tblFile = document.getElementsByClassName("tbl-file");
  if (tblFile.length > 0) {
    var tableDataFolderFile = window.$('.tbl-file').DataTable({
      'destroy': true,
      'columnDefs': [
        {
          'targets': 0,
          'width': "5%",
          "orderable": false,
          'checkboxes': {
            'selectRow': true
          }
        },
        { "width": "5%", "targets": 1 },
        { "width": "50%", "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "15%", "targets": 4 },
        { "width": "20%", "targets": 5 },
      ],
      'select': {
        'style': 'multi'
      },
      'order': [1, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      //add horizontal scrollbar code
      fnInitComplete: function () {
        // Enable TFOOT scoll bars
        $('.dataTables_scrollFoot').css('overflow', 'auto');

        //  Sync TFOOT scrolling with TBODY
        $('.dataTables_scrollFoot').on('scroll', function () {
          $('.dataTables_scrollBody').scrollLeft($(this).scrollLeft());
        });
      },
      scrollX: true,
      paging: true,
      fixedColumns: {
        leftColumns: 1
      },
      //end horizontal scrollbar code
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });

    $('body').delegate('#btn-download-file', 'click', function(e) {      
      var files = [],pdfs = [];
      var checkboxes = $(".tbl-file tr td .dt-checkboxes");
      var col_checkbox = null,pdf_id;
      var filezip = "Compress.zip";
      var node = getSelectedTreeDataFolder();
      if(node){
        filezip = $("#tree_view_data").jstree('get_selected', true)[0].text  + ".zip";
      }

      checkboxes.filter(':checked').map(function(){
        col_checkbox = $(this).closest('td')[0];
        pdf_id = parseInt(col_checkbox.getAttribute("data-pdf"));
        if(pdf_id){
          pdfs.push(pdf_id);
        }
        else{
          files.push({url: col_checkbox.getAttribute("data-url"),mime: col_checkbox.getAttribute("data-mime"),filename: col_checkbox.getAttribute("data-filename")});
        }
        
        // window.open(col_checkbox.getAttribute("data-url"));
      })
      if(files.length == 0 && pdfs.length == 0) return;  
      downloadMultipleFiles(files)   
      // download(files,pdfs,filezip);
     
    });

    function downloadMultipleFiles(files) {
      var tempLink = document.createElement('a');
      for (let index = 0; index < files.length; index++) {
        try {
          const element = files[index];
          tempLink.setAttribute('href', element.url);
          tempLink.setAttribute('download', element.filename);
          tempLink.click();
          
        } catch (error) {
          
        }
        

      }
      
  }
    async function download(files,pdfs,filezip){      
      try {
        showFormLoading();
        await download_helper.download_list_file(files,pdfs,filezip);
      } catch (error) {
        console.log(error)
      }
      finally{
        hideFormLoading();
      }  
    }

    window.getSelectedFileInTableDataFolder = function (){
      return GetListIdSelected(tableDataFolderFile).length;
    }

    $('body').delegate('.block-folder-relocation', 'click', function(e) {   

      var changed_parent_id = parseInt($(this).find(".folder-id").text());
      $.ajax({
      type:"GET",
      url:"/sub_datafolder_relocation",
      dataType:"script",
      data: {
        id: getSelectedTreeDataFolder(),
        parent_id:changed_parent_id
      },
      success: function(data, textStatus, jqXHR){
            
          },
          error:function(jqXHR, textStatus, errorThrown){
            console.log(jqXHR.responseText)
          }
      })
    });

    $('body').delegate('.block-file-relocation', 'click', function(e) {   
      var changed_parent_id = parseInt($(this).find(".folder-id").text());
      $.ajax({
      type:"GET",
      url:"/sub_folder_relocation_data_file",
      dataType:"script",
      data: {
        id: changed_parent_id,
      },
      success: function(data, textStatus, jqXHR){
            
          },
          error:function(jqXHR, textStatus, errorThrown){
            console.log(jqXHR.responseText)
          }
      })
    });

    $('body').delegate('#btn-delete-file', 'click', function(e) {
      var lst_selected = GetListIdSelected(tableDataFolderFile);
      var folder_id = getSelectedTreeDataFolder();  
      if (lst_selected.length == 0) return;
      $.ajax({
        type: 'GET',
        url: "/confirm_delete_multiple_file",
        dataType: 'script',
        data: {
          files: lst_selected,
          folder_id: folder_id
        },
        success: function (data, textStatus, jqXHR) {
          window.$("#loadMe").modal('hide');
          // console.log(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          window.$("#loadMe").modal('hide');
          console.log("AJAX Error: #{textStatus}")
        }
      });
    });
  }
}



// window.initTree = function (){
//   var elemtree = document.getElementById('datajs');
//   if(!elemtree) return;
  
//   var datajs = elemtree.value;
  
//   datajs = datajs.replace(/'/g, '"');
//   datajs = JSON.parse(datajs);
//   elemtree.remove();
//   $('#tree_view_data').jstree({    
//   core: {
//     // themes: { dots: false},
//     "check_callback": true,
//     'data' : datajs

//   } 
// })

// .on('loaded.jstree', function(e,data) {
//   var folder_id = $("#current-folder-id").val();
//   var childrens = $("#tree_view_data").jstree("get_children_dom","#");
//   if(childrens.length>0) 
//   {
//     if (folder_id != null) {
//       SetSelectedTree(folder_id);
//     }
//     else {
//       $('#tree_view_data').jstree('select_node', childrens[0].id);
//     }
//     $("#tree_view_data").jstree("open_node", childrens[0].id, false, true);
//   }

// })
// .on("select_node.jstree", function (e, data) { 
//     $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
//     $(`#${data.node.id}`).addClass("folder-mark-up");
//     if(data){
//     $.ajax({
//       type: "GET",
//       url: "sub_data_folder_block",
//       dataType: "script",
//       data: { folder_id: data.node.id },
//       success: function (data, textStatus, jqXHR) {
//         formatTableDataFolderFile();
//       },
//       error: function (jqXHR, textStatus, errorThrown) {
//         console.log(jqXHR.responseText)
//       }
//     })
//     $("#btnShowHistory").prop("href", "/folders/" + data.node.id);
//   }
// })

//   $("#tree_view_data").on('open_node.jstree', function (event, data) {   

//   });
// }
  
window.SetSelectedTree = function (id){
  $("#tree_view_data").jstree().deselect_all(true);
  $('#tree_view_data').jstree('select_node',id);
}

window.getSelectedTreeDataFolder = function (){
  var node_selected = $("#tree_view_data").jstree("get_selected");
  if(node_selected.length>0){
    return node_selected[0];
  }
  else{
    return "0";
  }
}



window.changeIconNode = function(current_folder_id) {
  $("#tree_view_data").jstree(true).set_icon(current_folder_id, "/assets/images/icon_folder.svg");
}

window.changeTextNode = function(node,name_folder) {
  $('#tree_view_data').jstree('rename_node', node, name_folder);
  $('li.jstree-open > i.jstree-icon.jstree-ocl').addClass('icon-plus');
  $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');
}

window.appendChildNodeDataFolder = function (idChild,text,text_folder_parent,icon_folder){
  // console.log($("#tree_view").jstree("get_selected").text());
  var node_selected = $("#tree_view_data").jstree("get_selected");
  if(node_selected.length>0){
     $('#tree_view_data').jstree().create_node(node_selected[0], {
      "id": idChild,
      "text": text,
      "icon": icon_folder
    }, "last", function() {
        var first_node = $("#tree_view_data").jstree("get_children_dom","#")[0].id;
        $('#' + node_selected[0]).find('i.jstree-icon.jstree-ocl').first().addClass('icon-plus');
        if (first_node != node_selected[0]) {
          $('#tree_view_data').jstree('rename_node', node_selected[0], text_folder_parent);
        }
        $("#tree_view_data").jstree("open_node", $('#' + node_selected[0]));
        $('li.jstree-open > i.jstree-icon.jstree-ocl').addClass('icon-plus');
        $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');
        $.ajax({
          type: "GET",
          url: "sub_data_folder_block",
          dataType: "script",
          data: { folder_id: node_selected[0] },
          success: function (data, textStatus, jqXHR) {
            
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        });
    });
  }
}

function LoadDataFolder() {     
  var treeDataFolder = initTreeDataFolder('tree_view_data',show_data);
  
  $('body').delegate('.item-data-folder-important', 'click', function(e) {
    const id = this.getAttribute("data-id")
    treeDataFolder.select_node(id);
  });

  $("body").delegate('.block-data-folder', 'click', function () {
    $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
    const current_folder_id = $(this).data("folder-id");
    treeDataFolder.select_node(current_folder_id);
  });
  
  $("body").delegate('#btnRelocationMultipleFile', 'click', function () {
    var lst_selected = [];
    var node_selected = $("#tree_relocation_folder").jstree("get_selected");
    if(node_selected.length > 0 ){
      var folder_id = parseInt(node_selected[0]);     
      $.each($('.tbl-file tbody tr'), function (i, row) {
        if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
          lst_selected.push($(row).find(".col_checkbox").data('item'));
        } 
      });
      if (lst_selected.length == 0) return;
      $.ajax({
        type: 'GET',
        url: window.origin +  "/update_location_multiple_file",
        dataType: 'script',
        data: {
          files: lst_selected,
          folder_id: folder_id
        },
        success: function (data, textStatus, jqXHR) {
          window.$("#modal-window-multiple-file-relocation-data-folder").modal('hide');
          // console.log(data);
          treeDataFolder.select_node(folder_id);
    
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
  });
}
function initTreeDataFolder(tree_id,callbackSelectNode) {
  var elemtree = el(tree_id);
  if(elemtree){
    var elemtreeData = document.getElementById('datajs');
    if(!elemtreeData) return;    
    var datajs = elemtreeData.value;    
    datajs = datajs.replace(/'/g, '"');
    datajs = JSON.parse(datajs);
    var id = elemtree.getAttribute('selected');      
    elemtreeData.remove();    
    return new Tree(elemtree,datajs,id,callbackSelectNode); 
  }
  
}
function show_data(id) { 
  if(id){  
    $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
    $(`#${id}`).addClass("folder-mark-up");
    $.ajax({
      type: "GET",
      url: "sub_data_folder_block",
      dataType: "script",
      data: { folder_id: id },
      success: function (data, textStatus, jqXHR) {
        formatTableDataFolderFile();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })   
    var currentUrl = window.origin + '/data_folders?folder_id=' + id;          
    var url = new URL(currentUrl);        
    history.pushState({}, "", url);  
  }
}

function show_path_relocation(folder_id){
  $.ajax({
    type:"GET",
    url:"/data_folders/show_path_relocation",
    dataType:"json",
    data: { id: folder_id },
    success: function(data, textStatus, jqXHR){
      var pathHtml = '';
      $.each(data.paths, function(index, path) {
        pathHtml += '<li class="breadcrumb-item active">' + path + '</li>';
      });
      var $pathName = $('#name-folder-after-relocation');
      $pathName.html(pathHtml);

    },
    error:function(jqXHR, textStatus, errorThrown){
      console.log(jqXHR.responseText)
    }
  })
}
