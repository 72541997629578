$(document).on('turbo:load', function() { 
    if (window.location.pathname.includes(`/pdf/upload_document`)) {
        settingDropZone('pdfDropZone');
    }

	
})

function settingDropZone(dropZoneId){ 
	$(function() {		
		var dopzone= document.getElementById(dropZoneId);
		if(dopzone){
		  $(`#${dropZoneId}`).dropzone({  
			url: "create_pdf_tem",
            maxFilesize: 16,
            maxFiles: 100,
            previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,
            acceptedFiles: getListAcceptFileType("pdf"),        
            clickable: true,
			accept: function(file, done) {
			  switch (file.type) {
					case 'application/pdf':
						file.previewElement.querySelector("img").src= '/assets/images/pdf.png';
						break;	
					default:
						var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
						progressElement.querySelector(".progress-text").textContent = "error";
						break;	
					}
			  done();
			},
			init: function() {		
			  this.on("error", function(file, message, xhr) { 
					if (file.type != 'application/pdf') {
						messageErrorFileType(file);
					}
					else {
						messageErrorFileSize(file, this.options.maxFilesize);
					}
					defineIcon(file);		
				});
	
			  this.on("addedfile", function(file) {
					document.getElementById('borrow_id').style.display="block";
					dopzone.style.display="block";
					dopzone.style.minHeight="min-content";               
			   });
				
				this.on("success", function(file, responseText) {   
					fileSelectd=responseText.file;
					checkEncryption(file,fileSelectd);
					file.previewElement.addEventListener("click", function() 
					{
						window.location.href = window.location.origin + `/pdf/edit/${fileSelectd.slug}`;              
					})
				});

				this.on("uploadprogress", function(file, progress, bytesSent) {
				  if (file.previewElement) {
					  var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
					  progressElement.style.width = progress + "%";
					  progressElement.querySelector(".progress-text").textContent = parseInt(progress) + "%";
				  }
				});			 
			}
			});	
		}
	});
}
function checkEncryption(file,fileSelectd) {
	const reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = function () {

	var files = new Blob([reader.result], {type: 'application/pdf'});
	
	files.text().then(x=> {		
		var isEncrypted =  x.includes("Encrypt");
		if(isEncrypted){
			file.previewTemplate.querySelector('.file-locked').classList.remove("d-none");
			$.ajax({
				type: 'POST',
				url: "set_encryption",
				dataType: 'json',
				data: {
				  pdf_id: fileSelectd.id,
				  isEncrypted: isEncrypted
				},
				success: function(data, textStatus, jqXHR){
				 
				},
				error:function(jqXHR, textStatus, errorThrown){
				  console.log("AJAX Error: #{textStatus}")
				}
			  });
		}
		
	});

}
}