export function drop_zone(id, type, url) {
  let myDropzone = null;
  $(function () {
    $('.dropzone').each(function () {

      let dropzoneControl = $(this)[0].dropzone;
      if (dropzoneControl) {
        dropzoneControl.destroy();
      }
    });
    //Dropzone class
    var dopzone = $(id);
    if (dopzone.length > 0) {
      myDropzone = $(id).dropzone({
        url: url,
        paramName: "file",
        maxFilesize: 50,
        maxFiles: 10,
        previewTemplate: `<div class="d-block" id="uploadPreviewTemplate">
                            <div class="card mt-1 mb-0 shadow-none border">
                              <div class="p-2">
                                <div class="row align-items-center">
                                  <div class="col-auto">
                                    <img data-dz-thumbnail src="/" class="avatar-sm rounded bg-light" alt="">
                                  </div>
                                  <div class="col pl-0">
                                    <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                                    <p class="mb-0" data-dz-size></p>
                                  </div>
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-primary" role="progressbar" data-dz-uploadprogress>
                                      <span class="progress-text">0%</span>
                                    </div>
                                  </div>
                                  <div class="col-auto">
                                    <!-- Button -->
                                    <a href="" class="btn btn-link btn-lg text-muted btn_destroy_pdf" data-dz-remove>
                                      <i class="dripicons-cross"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>`,

        maxFiles: 100,
        acceptedFiles: getListAcceptFileType(type),
        clickable: true,
        accept: function (file, done) { defineIcon(file, done) },
        init: function () {
        //   this.on("error", function(file, message, xhr) {
        // if (xhr == null)
        // {
        // this.removeFile(file);
        // }
        // alert(file.name + " upload error : "+ message);

        // });

        // this.on("addedfile", function(file) {

        // });

        // this.on("success", function(file, responseText)
        // {
        // var fileSelectd=responseText.file;
        // var removeButton = file.previewElement.querySelector('[data-dz-remove]');
        // removeButton.addEventListener("click", function (e) {
        // e.preventDefault();
        // e.stopPropagation();

        // })
        // });
        // this.on("uploadprogress", function(file, progress, bytesSent) {
        // if (file.previewElement) {
        // var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
        // progressElement.style.width = progress + "%";
        // progressElement.querySelector(".progress-text").textContent = progress + "%";
        // }
        // });

        }

      });

    }
  });
  return myDropzone;
}

window.getListAcceptFileType = function (type) {
  let format_video = ".mp4, .mkv, .avi, .wmv, .flv, .mov, .webm, .ogv"
  if (type == "default") return ".csv,.pdf, .doc, .docx, .xlsx, .xls, .xlsm, .png, .svg, .gif, .jpeg, .jpg, .ppt, .zip, .rar, .txt, .7z,application/x-7z-compressed " + format_video;
  if (type == "pdf") return ".pdf"; // only pdf
}

window.defineIcon = function (file) {
  switch (file.type) {
    case 'application/pdf':
      file.previewElement.querySelector("img").src = '/images/file/pdf.png';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      file.previewElement.querySelector("img").src = '/images/file/word.png';
      break;
    case 'application/msword':
      file.previewElement.querySelector("img").src = '/images/file/word.png';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      file.previewElement.querySelector("img").src = '/images/file/excel.png';
      break;
    case 'application/vnd.ms-excel':
      file.previewElement.querySelector("img").src = '/images/file/excel.png';
      break;
    case 'applications/ppt':
      file.previewElement.querySelector("img").src = '/images/file/power_point.png';
      break;
    case 'applications/pptx':
      file.previewElement.querySelector("img").src = '/images/file/power_point.png';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      file.previewElement.querySelector("img").src = '/images/file/power_point.png';
      break;
    case 'application/zip':
    case 'application/rar':
    case 'application/x-7z-compressed':
        file.previewElement.querySelector("img").src = '/images/file/zip.png';
        break;
    default:
      file.previewElement.querySelector("img").src = '/images/file/file.png';
      break;
  }
}

window.messageErrorFileSize = function (file, maxFilesize) {
  var sizeElement = file.previewElement.querySelector("[data-dz-size]");
  $('<p>', {
    class: 'mb-0 text-danger',
    text: I18n.t('dropzone.error_message', {size: maxFilesize})
  }).insertAfter(sizeElement);
}

window.messageErrorFileType = function (file) {
  var sizeElement = file.previewElement.querySelector("[data-dz-size]");
  $('<p>', {
    class: 'mb-0 text-danger',
    text: I18n.t('dropzone.error_message_type')
  }).insertAfter(sizeElement);
}
