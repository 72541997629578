//= require jquery
//= require jquery-ui
//= require jstree.min
//= require bootstrap-tokenfield
//= require jquery-ui/widgets/mouse
//= require jquery-ui/widgets/draggable
//= require jquery-ui/widgets/droppable
//= require jquery-ui/widgets/resizable
//= require jquery-ui/widgets/selectable
//= require jquery-ui/widgets/sortable
//= require bootstrap-tokenfield
//= require i18n
//= require i18n/translations
//= require_tree .

import Calendar from 'js-year-calendar';
import 'cocoon-js'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'select2'
require( 'datatables.net' )
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("jquery-validation")
global.$ = require("jquery")
global.moment = require('moment')
require("trix")



window.existingPdfBytes=null;
window.Dropzone = require("dropzone/dist/dropzone-min")
import I18n from 'i18n-js'
window.I18n = I18n
require("../../../public/libs/dhtmlxgantt/dhtmlxgantt")

require("quill-mention");
require("packs/common")
require("packs/common/canvas")
require("packs/common/button")

require("packs/dom_event")
require("packs/image_helper")
require("packs/convert")

require("packs/formats/datepicker")
require("packs/formats/table")
require("packs/formats/calendar")
require("packs/formats/select2")
// require("packs/validate_page") // check id duplicate

require("packs/download")
require("packs/format_datatable")
require("packs/setting")
require("packs/dropzone/dz")
require("packs/autheticate/login")
require("packs/users")
require("packs/group")
require("packs/log")
require("packs/logadmin")
require("packs/notification")
require("packs/left_bar")
require("packs/version_manager")
require("@rails/actiontext")
require("packs/requirement")
require("packs/pdf/index")
require("packs/pdf/complete")
require("packs/pdf/trash")
require("packs/pdf_show")
require("packs/pdf_edit")
require("packs/pdf_stamp")
require("packs/pdf/pdf")
require("packs/pdf/draw_stamp")
require("packs/pdf/decompose_pdf")
require("packs/pdf/arrange/init")
require("packs/pdf/merge_pdf/init")
require("packs/pdf/split/init")
require("packs/pdf/arrange/event_arrange_page")
require("packs/pdf/merge_pdf/event_merge_pdf")
require("packs/pdf/split/event_split_pdf")
require("packs/folder")
require("packs/file_information")
require("packs/ot_list")
require("packs/jstree.min")
require("packs/schedule")
require("packs/category")
require("packs/equipment")
require("packs/holiday")
require("packs/type_meeting")
require("packs/personal_mail")
require("packs/folder_mail")
require("packs/schedule2")
require("packs/data_folder")
require("packs/data_folder_file")
require("packs/platform")
require("packs/project")
require("packs/iso_file")
require("packs/user_setting")
require("packs/pdf/upload_document")
require("packs/dashboard")
require("packs/bulletin")
require("packs/bulletin_category")
require("packs/bulletin_subject")
require("packs/export_bulletins_form")
require("packs/schedule_equipment")
require("packs/list_modules")
require("packs/module_groups")
require("packs/top_page_information")
require("packs/language")
require("packs/permission")
require("packs/setting_interaction")
require("packs/personal_group")
require("packs/department")
require("packs/position")
require("packs/delete_schedule_all")
require("packs/set_pin_code")
require("packs/comment")
require("packs/log_time")
require("packs/time_sheet_by_day")
require("packs/setting_display_column")
require("packs/customer")
require("packs/job_type")
require("packs/status")
require("packs/processed")
require("packs/work")
require("packs/master_plan/new_master_plan.js")
require("packs/master_plan/master_plan_table.js")
require("packs/master_plan/role_member_master_plan.js")
require("packs/master_plan/project_workload.js")
require("packs/master_plan/dashboard.js")
require("packs/master_plan/overview.js")
require("packs/log_works")
require("packs/work_report")
require("packs/workload_by_user")
require("packs/shift")
require("packs/user_shift")
require("packs/project_category")
require("packs/issue/filter_issue.js")
require("packs/issue/issue_in_board.js")
require("packs/issue/issue_jobs.js")
require("packs/issue/issue_tags.js")
require("packs/issue/issues.js")
require("packs/issue/log_work_issue.js")
require("packs/issue/plan_time_issue.js")
require("packs/issue/moot.js")
require("packs/gantt/gantt")
require("packs/gantt/filter_issue")
require("packs/gantt/setting_column")
require("packs/gantt/add_multil_file")
require("packs/master_plan/template")
require("packs/validate_devise")
require("packs/stamp_setting")
require("packs/permission_folder_share_outer")
require("packs/partner")
require("packs/custom_stamp")
require("packs/master_plan/screen_property")
require("packs/master_plan/currency")
require("packs/trial_guest")
require("packs/pdf_user_share")
// require("packs/common/upload_folder")