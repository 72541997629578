$(document).on('turbo:load', function () {
    format_table();    
})

function format_table(){
    format_column_checkbox();
}

function format_column_checkbox(){   
    $("body").delegate('table .check-box-all', 'change', function () {   
        $('table tbody tr td .col_checkbox').prop('checked', $(this).prop('checked'));
    });
    $("body").delegate('table tr', 'click', function () {   
        var col_checkbox = this.querySelector('.col_checkbox');
        if(col_checkbox){
            $(col_checkbox).prop('checked', !$(col_checkbox).prop('checked'));
        }
    });
}
