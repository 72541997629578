import 'quill-mention';
import Quill from 'quill';
import Calendar from 'js-year-calendar';

$(document).on('turbo:load', function() {

	// load_js_personal_day();

	$("body").delegate('[class*=timeline_new]', 'click',create_timeline_new_simple_schedule);
	$("body").delegate('[class*=dnd-timehead-blrow]', 'click',create_timeline_new_simple_schedule);
	$("body").delegate('[class*=infocell]', 'click',create_timeline_new_simple_schedule);

	// $("[class*=timeline_new]").click(create_timeline_new_simple_schedule);
	// $("[class*=dnd-timehead-blrow]").click(create_timeline_new_simple_schedule);
	// $("[class*=infocell]").click(create_timeline_new_simple_schedule);
	

	// $(".tag_schedule").click(function(event) {
	$("body").delegate('.tag_schedule', 'click', function() {
		if (!this.classList.contains("moving")) {
			var schedule_id = this.getAttribute("data-id");
			var tab_calendar = this.getAttribute("tab-calendar");
			var user_id = this.getAttribute("user-id");
			var date = this.getAttribute("data-date"); // ngày user click
			var department_id = this.getAttribute("department-id");
			var cdate = this.getAttribute("cdate"); // ngày bắt đầu ở màn hình group day, week schedule, personal month
			var data_type = this.getAttribute("data-type");
			var equipment_id = this.getAttribute("equipment-id");
			var category_id = this.getAttribute("category-id");
			var current_page = this.getAttribute("data-tab-page");
			var element_id = this.id
			var lst_user = [];
			if (this.getAttribute("lst-user")) { lst_user = JSON.parse(this.getAttribute("lst-user")); } 
			var lst_equipment = [];
			if (this.getAttribute("lst-equipment")) { lst_equipment = JSON.parse(this.getAttribute("lst-equipment")); } 
			var status = "";
			if (this.getAttribute("status")) {status = this.getAttribute("status")}
			var page = this.getAttribute("data-page") ? this.getAttribute("data-page") : ""
			$.ajax ({
				type: 'GET',
				url: "/show_overview_schedule",
				dataType: 'script',
				data: {
						id: schedule_id,
						tab_calendar: tab_calendar,
						user_id: user_id,
						date: date,
						department_id: department_id,
						cdate: cdate,
						data_type: data_type,
						element_id: element_id,
						equipment_id: equipment_id,
						category_id: category_id,
						tab_page: current_page,
						lst_user: lst_user,
						lst_equipment: lst_equipment,
						status: status,
						page: page
				},
				success: function(data, textStatus, jqXHR){
					// console.log(data);
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate('#btnDeleteScheduleSimple', 'click', function () {
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    var check_date = $('#check_date').val();
    var page = $("#page").val();
    $.ajax ({
      type: 'GET',
      url: "/confirm_destroy_schedule_simple",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        check_date: check_date,
        page: page
      },
      success: function(data, textStatus, jqXHR){
        // 
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });
  
  $("body").delegate('#btnJoinScheduleSimple', 'click', function () {
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    var check_date = $('#check_date').val();
    var page = $("#page").val();
    $.ajax ({
      type: 'GET',
      url: "/join_schedule_simple",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        check_date: check_date,
        page: page
      },
      success: function(data, textStatus, jqXHR){
        // 
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });
 
	// $('html').on('click', function(e) {
	//   if (typeof $(e.target).data('bs-original-title') == 'undefined' && !$(e.target).parents().is('.popover.show') && ((e.target.id != 'link_schedule') || (e.target.classList.contains("tag_schedule")))) {
	//     window.$('[data-bs-original-title]').popover('hide');
	//   }
	// });

	// use js-year-calendar for personal year
	if ($(".calendar-personal-year").length > 0) {
		var current_page = $("#tab_page").val();
		var data_type = document.getElementById("data_type").value;
		var department_id = document.getElementById("department_id").value;
		var user_id = document.getElementById("user_id").value;
		var date_selected = document.getElementById("date_selected").value;
		var currentYear = new Date(date_selected).getFullYear(); 
		window.calendar_year_schedule = new Calendar('.calendar-personal-year', {
			style: 'custom',
			language: I18n.locale,
			displayHeader: false,
			startYear: currentYear,
			yearChanged: function(e) {
				// getListHoliday(currentYear, data_type, user_id, department_id, date_selected)
			},
			customDayRenderer: function(element, date) {
				var dateString = formatDate(date);

				var current_date = (new Date()).toLocaleDateString("fr-CA"); // convert date format yyyy-mm-dd
				if (date.toLocaleDateString("fr-CA") == current_date) {
					element.style.background = "#3688fc";
					element.style.color = "white";
				}

				var list_holiday = document.getElementById("list_holiday").value.split(",");
				list_holiday.forEach(function(item) {
					if (item == dateString) {
						element.style.color = "#f75c90";
					}
				})
			},
			clickDay: function(e) {
				var dateString = e.date.toLocaleDateString("fr-CA"); // convert date format yyyy-mm-dd
				window.location.href = "/personal_day?date_type=" + data_type + "&department_id=" + department_id + "&tab_page=" + current_page + "&start_date=" + dateString + "&user_id=" + user_id;
			},
			renderEnd: function(e) {
				// $("#text_fulldate").html(e.currentYear);
			}
		});
	}

	$("#btn_this_year").click(function() {
		var year_selected = new Date().getFullYear();
		var data_type = document.getElementById("data_type").value;
		var department_id = document.getElementById("department_id").value;
		var user_id = document.getElementById("user_id").value;
		var date_selected = document.getElementById("date_selected").value;
		var current_page = $("#tab_page").val();
		getListHoliday(year_selected, data_type, user_id, department_id, date_selected, current_page)
	});

	$("#btn_next_year").click(function() {
		var year_selected = window.calendar_year_schedule.getYear() + 1;
		var data_type = document.getElementById("data_type").value;
		var department_id = document.getElementById("department_id").value;
		var user_id = document.getElementById("user_id").value;
		var date_selected = document.getElementById("date_selected").value;
		var current_page = $("#tab_page").val();
		getListHoliday(year_selected, data_type, user_id, department_id, date_selected, current_page)
	});

	$("#btn_prev_year").click(function() {
		var year_selected = window.calendar_year_schedule.getYear() - 1;
		var data_type = document.getElementById("data_type").value;
		var department_id = document.getElementById("department_id").value;
		var user_id = document.getElementById("user_id").value;
		var date_selected = document.getElementById("date_selected").value;
		var current_page = $("#tab_page").val();
		getListHoliday(year_selected, data_type, user_id, department_id, date_selected, current_page);
	});

	// draggable week schedule
		// drag_week_schedule();
		
		
	// drop week schedule
		// drop_week_schedule();
		
			

	// Quill for note in form new/edit schedule
		if ($("#editor_schedule").length > 0) {
			if ($(".new_schedule").length > 0) {
				var form = document.querySelector('.new_schedule');
			}

			if ($(".edit_schedule").length > 0) {
	      var form = document.querySelector('.edit_schedule');
	    }
			var quill = format_quill_schedule('#editor_schedule');
			form.onsubmit = function() {
				var postContentInput = document.querySelector('#schedule_note');
				postContentInput.value = quill.root.innerHTML;
			};
		}

	// popover copy link on screen show schedule
  // $("#link_schedule").click(function() {
  $('body').delegate('#link_schedule', 'click', function() {
    $.ajax({
      type: 'GET',
      url: "/copy_link",
      dataType: 'script',
      data: {},
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

  // draggable month schedule
  	// drag_month_schedule();
  	
	// droppable month schedule
  	// drop_month_schedule();

	// handle create repeat schedule skip days
	$('body').delegate('#btnCreateRepeatScheduleSkipDays', 'click', function() {
		if ($("#btn-save-repeat-schedule").length > 0) {
			$("#btn-save-repeat-schedule").click();
		} 
		if($("#btnUpdateRepeatSchedule").length > 0) {
			$("#btnUpdateRepeatSchedule").click();
		}
		
	});

	$("body").delegate("#btnDetailSetting", 'click', function() {
		document.getElementById("draft_schedule").value = true;
		$("#btnSave_simple_schedule").click();
	});

	$("body").delegate("#btnDetailSettingDashboard", 'click', function() {
		document.getElementById("draft_schedule").value = true;
		$("#btnSaveSimpleScheduleDashboard").click();
	});

});

window.drop_month_schedule = function() {
	$(".table-month-schedule tbody tr td.custom-td-month-calendar").droppable({
		accept: function(draggable) {
			return $.contains(this.parentNode.parentNode, draggable[0]);
		},
		hoverClass: "droppable-hover-td",
		drop: function(event, ui) {
			ui.draggable.appendTo($(this)).css({
        top: "0.4em",
        left: "auto"
      });
      var date_column = Date.parse(ui.helper[0].getAttribute("data-date"));
      var date_selected = Date.parse(ui.helper[0].parentElement.getAttribute("data-date"));
      if (date_column != date_selected) {
      	var schedule_id = ui.helper[0].getAttribute("data-id");
				var date_selected = ui.helper[0].parentElement.getAttribute("data-date");
				var user_id = ui.helper[0].getAttribute("user-id");
				var department_id = ui.helper[0].getAttribute("department-id");
				var current_date = ui.helper[0].getAttribute("data-date");
				var tab_page = ui.helper[0].getAttribute("data-tab-page");
				edit_week_schedule(schedule_id, date_selected, user_id, department_id, current_date, tab_page);
      }
      window.$("#" + ui.helper[0].id).popover('update');
		},
	});
}

window.drag_month_schedule = function() {
	$(".drag_month_schedule").draggable({
		revert: "invalid",
		stack: ".drag_month_schedule",
		containment: ".table-month-schedule tbody",
		start: function ( event, ui ) {
			$(this).addClass('moving');
			window.$(this).popover('dispose');
		},
		drag: function (event, ui) {
			$(this).addClass('moving');
		},
		stop: function( event, ui ) {
			$(this).removeClass('moving');
			// window.$(this).popover('dispose');
			// window.$(this).popover({
			//     html: true,
			//     trigger: 'focus',
			//     sanitize: false,
			// });
		}
	});
}

window.load_js_personal_day = function() {
	$('.timeline_new').hover(function(){
		this.style.background="rgb(204, 204, 255)";
		var cb7blank = this.getAttribute("cb7blank");
		$(".dnd-timehead-blrow[cb7blank="+cb7blank+"]")[0].style.background="rgb(204, 204, 255)";
	},
	 function(){
		this.style.background="";
		var cb7blank = this.getAttribute("cb7blank");
		$(".dnd-timehead-blrow[cb7blank="+cb7blank+"]")[0].style.background="";
	});


	$('.dnd-timehead-blrow').hover(function(){
		this.style.background="rgb(204, 204, 255)";
		var cb7blank = this.getAttribute("cb7blank");
		$(".timeline_new[cb7blank="+cb7blank+"]")[0].style.background="rgb(204, 204, 255)";

	},
	 function(){
		this.style.background="";
		var cb7blank = this.getAttribute("cb7blank");
		$(".timeline_new[cb7blank="+cb7blank+"]")[0].style.background="";
	});

	$('.dnd-inforow').hover(function(){
		this.style.background="rgb(204, 204, 255)";       
	},
	 function(){
		this.style.background="";       
	});

	$("#dndEventbodytableDate .ui-resizable").resizable({
			// axis: "y",
			animateEasing: "easeOutBounce",
			aspectRatio: true,
			ghost: true,
			handles: "n,s",
			// autoHide: true,
			grid: [ 0, 24 ],
			// containment: '.dnd-eventbodytable-date',
			resize: function(event, ui) {
					ui.size.width = ui.originalSize.width;
					ui.position.left = ui.originalPosition.left;
					// update_schedule(event, ui);
			},
			stop: function(event, ui) {
					update_schedule(event, ui);
					return;
			}
	});

	$('#dndEventbodytableDate .ui-resizable').hover(function() {
		var type_schedule = parseInt(this.getAttribute("type-schedule"));
		if (type_schedule == 1 || type_schedule == 4)
		{
			const topDiv = document.createElement("div");
			topDiv.className = "dnd-resize-handle dnd-resize-handle-top";
			this.appendChild(topDiv);

			const bottomDiv = document.createElement("div");
			bottomDiv.className = "dnd-resize-handle dnd-resize-handle-bottom";
			this.appendChild(bottomDiv);
		}
	},
	function(){
		var type_schedule = parseInt(this.getAttribute("type-schedule"));
		if (type_schedule == 1 || type_schedule == 4)
		 {
			var top = this.querySelectorAll(".dnd-resize-handle-top");
			if(top.length>0)  top[0].remove();
			
			var bottom = this.querySelectorAll(".dnd-resize-handle-bottom");
			if(bottom.length>0)  bottom[0].remove();
		}
	});

	$("#dndEventbodytableDate .ui-draggable").draggable({
		axis: "y",
		grid: [ 0, 24 ],
		cancel: ".dnd-eventdiv-not-draggable",
		containment: "#dndEventbodytableDate",
		stack: "div.dnd-item",
		stop: function( event, ui ) {
			update_draggable_schedule(event, ui);
			// console.log("a")
		}
	});
}

window.drag_week_schedule = function() {
	// $(".drag_schedule").draggable({
	// 	revert: "invalid",
	// 	stack: ".drag_schedule",
	// 	containment: ".tbl_week tbody",
	// 	start: function ( event, ui ) {
	// 		$(this).addClass('moving');
	// 		window.$(this).popover('dispose');
	// 	},
	// 	drag: function (event, ui) {
	// 		$(this).addClass('moving');
	// 	},
	// 	stop: function( event, ui ) {
	// 		$(this).removeClass('moving');
	// 		// window.$(this).popover('dispose');
	// 		// window.$(this).popover({
	// 		//     html: true,
	// 		//     trigger: 'focus',
	// 		//     sanitize: false,
	// 		// });
	// 	}
	// });
}

window.drop_week_schedule = function () {
	// $(".tbl_week tbody tr td.td-week-custom-padding").droppable({
	// // $("body").delegate('.tbl_week tbody tr td.td-week-custom-padding', 'droppable', function () {
	// 	accept: function(draggable) {
	// 		return $.contains(this.parentNode, draggable[0]);
	// 	},
	// 	hoverClass: "droppable-hover-td",
	// 	drop: function(event, ui) {
	// 		ui.draggable.appendTo($(this)).css({
  //       top: "0.4em",
  //       left: "auto"
  //     });
  //     var date_column = Date.parse(ui.helper[0].getAttribute("data-date"));
  //     var date_selected = Date.parse(ui.helper[0].parentElement.getAttribute("data-date"));
  //     if (date_column != date_selected) {
  //     	var schedule_id = ui.helper[0].getAttribute("data-id");
	// 			var date_selected = ui.helper[0].parentElement.getAttribute("data-date");
	// 			var user_id = ui.helper[0].getAttribute("user-id");
	// 			var department_id = ui.helper[0].getAttribute("department-id");
	// 			var current_date = ui.helper[0].getAttribute("data-date");
	// 			var tab_page = ui.helper[0].getAttribute("data-tab-page");
	// 			edit_week_schedule(schedule_id, date_selected, user_id, department_id, current_date, tab_page);
  //     }
  //     window.$("#" + ui.helper[0].id).popover('update');
	// 	},
	// });
}

window.format_quill_schedule = function(element_id) {
	var quill = new Quill(element_id, {
				theme: 'snow',
				modules: {
					'toolbar': [
						[{ 'font': [] }, { 'size': [] }],
						['bold', 'italic', 'underline', 'strike'],
						[{ 'color': [] }, { 'background': [] }],
						[{ 'script': 'super' }, { 'script': 'sub' }],
						[{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
						['direction', { 'align': [] }],
						['link'],
						['clean']
					]
				},
			});
	return quill;
}

function edit_week_schedule(schedule_id, date_selected, user_id, department_id, current_date, current_page) {
	$.ajax({
    type: 'GET',
    url: "/confirm_update_week_schedule",
    dataType: 'script',
    data: {
      schedule_id: schedule_id,
      date_selected: date_selected,
      user_id: user_id,
      department_id: department_id,
      current_date: current_date,
      tab_page: current_page
    },
    success: function(data, textStatus, jqXHR){
    },
    error:function(jqXHR, textStatus, errorThrown){
      console.log("AJAX Error")
    }
  });
}

function getListHoliday(year_selected, data_type, user_id, department_id, date_selected, current_page) {
	$.ajax({
		type: "GET",
		url: "/load_holiday_year",
		dataType: "script",
		data: { 
			year_selected: year_selected,
			data_type: data_type,
			user_id: user_id,
			department_id: department_id,
			current_date: date_selected,
			tab_page: current_page
		},
		success: function (data, textStatus, jqXHR) {
			
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("AJAX Error")
		}
	});
}

function update_draggable_schedule(event, ui) {
	var row_changed = Math.round((ui.position.top - ui.originalPosition.top)/24);
	var old_sh = ui.helper[0].getAttribute("cb7sh");
	var old_sm = ui.helper[0].getAttribute("cb7sm");

	var old_eh = ui.helper[0].getAttribute("cb7eh");
	var old_em = ui.helper[0].getAttribute("cb7em");

	var dateString = ui.helper[0].getAttribute("cb7sd");
	var oldStartDate = new Date(dateString + "T00:00:00");
	oldStartDate.setHours(old_sh);
	oldStartDate.setMinutes(old_sm);

	var oldEndDate = new Date(dateString + "T00:00:00");
	oldEndDate.setHours(old_eh);
	oldEndDate.setMinutes(old_em);

	var minutes = row_changed*30;
	var newStartTime = new Date(oldStartDate.getTime() + minutes * 60000);
	var newEndTime = new Date(oldEndDate.getTime() + minutes * 60000);

	var type_schedule = ui.helper[0].getAttribute("type-schedule");
	var current_date = ui.helper[0].getAttribute("cb7date");
	var id_schedule = ui.helper[0].getAttribute("data-cb-eid");
	if(parseInt(type_schedule) == 4){
		confirm_change_schedule(id_schedule,newStartTime,newEndTime, current_date);
	}
	else {
		sub_update_schedule(id_schedule,newStartTime,newEndTime, current_date);
	}
	
	// if(isOK)
	// {
	//     // update and reload with ajax        
	//     sub_update_schedule(id_schedule,newStartTime,newEndTime, current_date);
	// }
	// else
	// {
	//     // reset resize
	//     ui.position.top = ui.originalPosition.top;
	//     ui.size.left = ui.originalSize.left;
	// }
	console.log("a");
}

function update_schedule(event, ui){
		// var isOK = true;
		var row_changed = Math.round((ui.position.top - ui.originalPosition.top)/24);
		console.log("row change:" + row_changed);
		var row_height = Math.round(ui.size.height/24);
		var old_sh = ui.element[0].getAttribute("cb7sh");
		var old_sm = ui.element[0].getAttribute("cb7sm");

		var dateString = ui.element[0].getAttribute("cb7sd");
		var oldDate = new Date(dateString + "T00:00:00");
		oldDate.setHours(old_sh);
		oldDate.setMinutes(old_sm);

		var minutes = row_changed*30;
		var newStartTime = new Date(oldDate.getTime() + minutes * 60000);
		var newEndTime = new Date(newStartTime.getTime() + row_height*30 * 60000);
		var id_schedule = ui.element[0].getAttribute("data-cb-eid");
		var type_schedule = ui.element[0].getAttribute("type-schedule");
		var current_date = ui.element[0].getAttribute("cb7date");
		if(parseInt(type_schedule) == 4){
			confirm_change_schedule(id_schedule,newStartTime,newEndTime, current_date);
		}
		else {
			sub_update_schedule(id_schedule,newStartTime,newEndTime, current_date);
		}
		// if(isOK)
		// {
		//     // update and reload with ajax        
		//     sub_update_schedule(id_schedule,newStartTime,newEndTime, current_date);
		// }
		// else
		// {
		//     // reset resize
		//     // ui.position.top = ui.originalPosition.top;
		//     // ui.size.height = ui.originalSize.height;
		//     // ui.element.css(ui.originalSize);
		//     // ui.element.top(ui.originalPosition.top);
		//     ui.element[0].style.top = $(".timeline_new[cb7sh="+old_sh+"][cb7sm=" +old_sm+"]")[0].offsetTop + "px";
		//     // ui.element[0].style.top=ui.originalElement[0].style.top;

		//     ui.element.height(ui.originalSize.height);
		//     ui.element.width(ui.originalSize.width);
		// }
}

function confirm_change_schedule(id_schedule,newStartTime,newEndTime, current_date){
		// dialog OK Cancel ?
	var user_id = $("#list_user_personal_day option:selected").val();
	var department_id = $("#department_id_personal_day").val();
	var start_hour = $("#start_hour").val();
	var end_hour = $("#end_hour").val();
	$.ajax ({
		type: 'GET',
		url: "/confirm_change_schedule",
		dataType: 'script',
		data: {
				id: id_schedule,
				start_time: newStartTime,
				end_time: newEndTime,
				user_id: user_id,
				department_id: department_id,
				current_date: current_date,
				start_hour: start_hour,
				end_hour: end_hour    
		},
		success: function(data, textStatus, jqXHR){
				// 
		},
		error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
		}
	});

	
}

function sub_update_schedule(id_schedule,newStartTime,newEndTime, current_date){
		console.log("UPDATE SCHEDULE");
		console.log("   id : " + id_schedule);
		console.log("   start : " + newStartTime);
		console.log("   end : " + newEndTime);
		var user_id = $("#list_user_personal_day option:selected").val();
		var department_id = $("#department_id_personal_day").val();
		var start_hour = $("#start_hour").val();
		var end_hour = $("#end_hour").val();
		var equipment_id = $("#list_equipment_personal_day option:selected").val();
		$.ajax ({
			type: 'GET',
			url: "/update_time_schedule",
			dataType: 'script',
			data: {
					id: id_schedule,
					start_time: newStartTime,
					end_time: newEndTime,
					user_id: user_id,
					department_id: department_id,
					current_date: current_date,
					start_hour: start_hour,
					end_hour: end_hour,
					equipment_id: equipment_id      
			},
			success: function(data, textStatus, jqXHR){
			},
			error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
			}
		});
		return true;
}

function create_timeline_new_simple_schedule(){
	var cb7date = this.getAttribute("cb7date");
	if(cb7date == null) return;
	var date = cb7date;
	var start_hour = this.getAttribute("cb7sh");
	var start_minute = this.getAttribute("cb7sm");
	var end_hour = this.getAttribute("cb7eh");
	var end_minute = this.getAttribute("cb7em");
	var user_id = $("#list_user_personal_day option:selected").val();
	var department_id = $("#department_id_personal_day").val();
	var equipment_id = $("#list_equipment_personal_day option:selected").val();
	var data_type = $("#data_type").val();
	var current_page = $("#tab_page").val();
 	$.ajax ({
		type: 'GET',
		url: "/new_simple_schedule",
		dataType: 'script',
		data: {
			day: date,
			start_hour: start_hour,
			start_minute: start_minute,
			end_hour: end_hour,
			end_minute: end_minute,
			user_id: user_id,
			tab_calendar: 4,
			tab_type_schedule: 1,
			department_id: department_id,
			equipment_id: equipment_id,
			data_type: data_type,
			tab_page: current_page,
			date_select: date,
			cdate: date
		},
		success: function(data, textStatus, jqXHR){
				// 
		},
		error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
		}
	});
}

window.dataTableScheduleDuplicate = function() {
	var tblScheduleDuplicate = document.getElementsByClassName("tbl_schedule_duplicate");
	if (tblScheduleDuplicate.length > 0) {
		window.$('.tbl_schedule_duplicate').DataTable({
			'destroy': true,
			"lengthMenu": [10,20,30,50],
			'columnDefs': [
				{"width": "25%", "targets": 0 },
				{ "width": "25%", "targets": 1 },
				{ "width": "30%", "targets": 2 },
				{ "width": "20%", "targets": 3 },

			],
			// 'select': {
			// 	'style': 'multi'
			// },
			'order': [0, 'asc'], 
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"},
			emptyTable: I18n.t('emptyTableMessage'),
			info: I18n.t('infoCustom'),
			infoEmpty: I18n.t('infoEmptyCustom'),
			lengthMenu : I18n.t('lengthMenuCustom'),
			search: I18n.t('searchCustom'),
			zeroRecords: I18n.t('zeroRecordsCustom'),
		}
				
		});
	}
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

