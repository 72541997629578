import {setLeft} from './pdf/decompose_pdf.js';
$(document).on('turbo:load', function () {
	formatDataTable();
})

window.formatDataTable = function inItDatatable(id=null){	
	$('.dataTables_length').parent().parent().remove();
	$('.dataTables_paginate').parent().remove();
	$('.dataTables_info').parent().remove();
	formatTableUser(id);
	// formatTablePdf(id);
	// formatTablePdfShareWithMe(id);
	//formatTablePdfComplete(id);
	// formatTableTrash(id);
	formatTableMergePdf(id);
	formatTableDepartment(id);
	formatTablePosition(id);
	formatTblUploadDocument(id);	
	formatTableFoldeSearch();
}

function setSelectedRows(table,id){
	if(id && table){
		table.rows().every (function (rowIdx, tableLoop, rowLoop) {
			console.log(parseInt(this.data()[0]));
			if (parseInt(this.data()[0]) == parseInt(id)) {
				this.select ();
				this.show().draw(false); 
				return;
			}
		});
	}
}
function formatTableUser(id){
	var tblUser = document.getElementsByClassName("tbl_user");				
	if (tblUser.length > 0  ) {
		tblUser = window.$('.tbl_user').DataTable({
					'stateSave': false,
					'destroy': true,
					'fixedColumns': true,
					'columnDefs': [
					{
						'targets': 0,
						'width': "4%",
						'checkboxes': {
						'selectRow': true
						}
					},
					{ "width": "6%", "targets": 1 },
					{ "width": "9%", "targets": 2 },
					{ "width": "9%", "targets": 3 },
					{ "width": "10%", "targets": 4 },
					{ "width": "20%", "targets": 5 },
					{ "width": "10%", "targets": 6 },
					{ "width": "9%", "targets": 7 },
					{ "width": "8.5%", "targets": 8 },
					{ "width": "6%", "targets": 9 },
					{ "width": "9%", "targets": 10 }
					],
					'select': {
					'style': 'multi'
					},
					'order': [1, 'asc'],
					drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language:{paginate:{
					previous:"<i class='mdi mdi-chevron-left'>",
					next:"<i class='mdi mdi-chevron-right'>"}},
		});
		setSelectedRows(tblUser,id);
	}
}

function formatTablePdf(id){   
var tbl_pdf = document.getElementsByClassName("tbl_pdf");
if (tbl_pdf.length > 0) {
	tbl_pdf = window.$('.tbl_pdf').DataTable({
		'stateSave': false,
		'destroy': true,
		'fixedColumns': true,
		'columnDefs': [
		{
				'targets': 0,
				'width': "4%",
				'checkboxes': {
				'selectRow': true
				}
		},
		{ "width": "6%", "targets": 1 }

		
		],
		'select': {
		'style': 'multi'
		},
		// 'order': [1, 'asc'],
		drawCallback: function() {
		$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
		},
		language:{paginate:{
		previous:"<i class='mdi mdi-chevron-left'>",
		next:"<i class='mdi mdi-chevron-right'>"}},
		});
		setSelectedRows(tbl_pdf,id);
	};
	
	$("#btnDeletePdf").click(function() {
	var id_pdf;
	var lstPdf = [];
	$.each(tbl_pdf.rows({ 'search': 'applied' }).nodes(), function (i, row) {
			var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
			if (checkBox) 
			{
			id_pdf =parseInt(row.querySelector('.col_checkbox').getAttribute('data-item'));
			lstPdf.push(id_pdf);
			return;                
			}   
	});
	if(lstPdf.length==0) return;
	$.ajax({
			type: 'GET',
			url: "confirm_delete_multi_pdf",
			dataType: 'script',
			data: {
			list_id_pdf: lstPdf
			},
			success: function(data, textStatus, jqXHR){
			
			},
			error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error: #{textStatus}")
			}
	});
	});
			 
}

function formatTablePdfShareWithMe(id){
	var tbl_pdf_shared = document.getElementsByClassName("tbl_pdf_shared");
	if (tbl_pdf_shared.length > 0) {
			tbl_pdf_shared = window.$('.tbl_pdf_shared').DataTable({
					'stateSave': false,
					'destroy': true,
					'fixedColumns': true,
					'columnDefs': [
					{ "width": "4%", "targets": 0 },
					{ "width": "6%", "targets": 1 }            
					],
					'select': {
					'style': 'multi'
					},
					// 'order': [1, 'asc'],
					drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language:{paginate:{
					previous:"<i class='mdi mdi-chevron-left'>",
					next:"<i class='mdi mdi-chevron-right'>"}},
			});
	};
				
}

function formatTablePdfComplete(id){
	var tbl_pdf_complete = document.getElementsByClassName("tbl_pdf_complete");
	if (tbl_pdf_complete.length > 0) {
			tbl_pdf_complete = window.$('.tbl_pdf_complete').DataTable({
					'stateSave': false,
					'destroy': true,
					'fixedColumns': true,

					'columnDefs': [
					{
							'targets': 0,
							'width': "4%",
							'checkboxes': {
							'selectRow': true
							}
					},
					{ "width": "6%", "targets": 1 },
					{ "width": "30%", "targets": 2 },
					{ "width": "20%", "targets": 3 },
					{ "width": "20%", "targets": 4 }
					],
					'select': {
					'style': 'multi'
					},
					'order': [1, 'asc'],
					drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language:{paginate:{
					previous:"<i class='mdi mdi-chevron-left'>",
					next:"<i class='mdi mdi-chevron-right'>"}},
			});
	};
	// $("#btnDownloadMultiFile").click(function() {
	// 		var id_pdf;
	// 		var lstPdf = [];
	// 		$.each(tbl_pdf_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
	// 				var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
	// 				if (checkBox) 
	// 				{
	// 						id_pdf =row.querySelector('.col_checkbox').getAttribute('data-item');
	// 						lstPdf.push(id_pdf);
	// 						return;                
	// 				}   
	// 		});

	// 		if(lstPdf.length==0) return;
	// 		window.location.href = window.origin + '/pdf/download_multi_file?list_id_pdf='+lstPdf;
	// 		hideFormLoading();
	// });

	$("#btnDeleteLogicPdf").click(function() {
			var id_pdf;
			var lstPdf = [];
			$.each(tbl_pdf_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
					id_pdf =row.querySelector('.col_checkbox').getAttribute('data-item');
					lstPdf.push(id_pdf);
					return;                
					}   
			});
			if(lstPdf.length==0) return;
			$.ajax({
					type: 'GET',
					url: "confirm_delete_multi_pdf_logic",
					dataType: 'script',
					data: {
					list_id_pdf: lstPdf
					},
					success: function(data, textStatus, jqXHR){
					
					},
					error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
					}
			});
	});

	
}
function formatTableMergePdf(id){
	var tbl_select_complete = document.getElementsByClassName("tbl_select_complete");
	if (tbl_select_complete.length > 0) {
			tbl_select_complete = window.$('.tbl_select_complete').DataTable({
					'stateSave': false,
					'destroy': true,
					'fixedColumns': true,

					'columnDefs': [
					{
							'targets': 0,
							'width': "4%",
							'checkboxes': {
							'selectRow': true
							}
					}],
					'select': {
					'style': 'multi'
					},
					'order': [1, 'asc'],
					drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language:{paginate:{
					previous:"<i class='mdi mdi-chevron-left'>",
					next:"<i class='mdi mdi-chevron-right'>"}},
			});
			$("body").delegate('#btnPushPDF', 'click', function () {
					var id_pdf,filename;
					var lstPdf = [];
					var option;
					var select = document.getElementById("lstPdfMerge");
					$.each(tbl_select_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
							var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
							if (checkBox) 
							{
									id_pdf =row.querySelector('#col_checkbox').getAttribute('data-item');
									filename =row.querySelector('#col_file_name').textContent;
									option = document.createElement("option");
									option.text = filename;
									option.value = id_pdf;				
									select.appendChild(option);                
							}   
					});		
					$(".btn_close_modal").click();
			});
			$("body").delegate('#btnSetPdf', 'click', function () {

					var lstPath = [],path_pdf;
					$.each(tbl_select_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
						var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
						if (checkBox) 
						{
							path_pdf =row.querySelector('#col_checkbox').getAttribute('data-path');			 
							lstPath.push(path_pdf);      
						}   
					});		
			
					if(lstPath.length < 1) return;
			
					$(".btn_close_modal").click();
					setLeft(lstPath);
					
				});
	};	
}
function formatTableTrash(id){
var tbl_trash = document.getElementsByClassName("tbl_trash");
if (tbl_trash.length > 0) {
	tbl_trash = window.$('.tbl_trash').DataTable({
			'stateSave': false,
			'destroy': true,
			'fixedColumns': true,

			'columnDefs': [
			{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
					'selectRow': true
					}
			},
			{ "width": "6%", "targets": 1 }
			
			],
			'select': {
			'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function() {
			$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
			previous:"<i class='mdi mdi-chevron-left'>",
			next:"<i class='mdi mdi-chevron-right'>"}},
	});
	};
	$("#btnRestoreLogicPdf").click(function() {
			var id_pdf;
			var lstPdf = [];           
			$.each(tbl_trash.rows({ 'search': 'applied' }).nodes(), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id_pdf =row.querySelector('.col_checkbox').getAttribute('data-item');
							lstPdf.push(id_pdf);
							return;                
					}   
			});
			if(lstPdf.length==0) return;
			$.ajax({
				type: 'GET',
				url: "confirm_restore_multifile",
				dataType: 'script',
				data: {
					list_id_pdf: lstPdf
				},
				success: function(data, textStatus, jqXHR){
					
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
				}
			});

	});
		
}

function formatTableDepartment(id){
	var tbl_depart = document.getElementsByClassName("tbl_depart");
	if (tbl_depart.length > 0) {
		tbl_depart = window.$('.tbl_depart').DataTable({
				'stateSave': false,
				'destroy': true,
				'fixedColumns': true,
				"lengthMenu": [5,10,15,20],
				'columnDefs': [
				{
						'targets': 0,
						'width': "4%",
						'checkboxes': {
						'selectRow': true
						}
				},
				{ "width": "6%", "targets": 1 },
				{ "width": "30%", "targets": 2 }                
				],
				'select': {
				'style': 'multi'
				},
				'order': [1, 'asc'],
				drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
		setSelectedRows(tbl_depart,id);
			
	};
	$("#btn_edit_department").click(function() {
			var mydata = [],id;

			$.each($('.tbl_depart tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.querySelector('.col_checkbox').getAttribute('data-item');
							mydata.push(id);
							return;                
					}   
			});

			if(mydata.length!=1) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "/departments/" + mydata[0] + "/edit",
					dataType: 'script',
					data: {
									id: mydata[0]
					},
					success: function(data, textStatus, jqXHR){
					},
					error:function(jqXHR, textStatus, errorThrown){
									console.log("AJAX Error: #{textStatus}")
					}
			})
	});

	$("#btn_delete_department").click(function() {
			var mydata = [],id;

			$.each($('.tbl_depart tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.querySelector('.col_checkbox').getAttribute('data-item');
							mydata.push(id);
										 
					}   
			});

			if(mydata.length==0) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "delete_department",
					dataType: 'script',
					data: {
									data: {ids:mydata},
					},
					success: function(data, textStatus, jqXHR){
							console.log("AJAX OK!")
					},
					error:function(jqXHR, textStatus, errorThrown){
							console.log("AJAX Error: #{textStatus}")
					}
			})
	});

	$('a[href="#profile"]').on('click', function(event){
		formatTablePosition();
	});

}    

function formatTablePosition(id){
	var tbl_position = document.getElementsByClassName("tbl_position");
	if (tbl_position.length > 0) {
		tbl_position = window.$('.tbl_position').DataTable({
				'stateSave': false,
				'destroy': true,
				'fixedColumns': true,
				"lengthMenu": [5,10,15,20],
				'columnDefs': [
				{
						'targets': 0,
						'width': "4%",
						'checkboxes': {
						'selectRow': true
						}
				},
				{ "width": "6%", "targets": 1 },
				{ "width": "30%", "targets": 2 }                
				],
				'select': {
				'style': 'multi'
				},
				'order': [1, 'asc'],
				drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
		setSelectedRows(tbl_position,id);
	};

	$("#btnEditPosition").click(function() {
		var mydata = [],id;

		$.each($('.tbl_position tbody tr'), function (i, row) {
			var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
			if (checkBox) {
				id =row.querySelector('.col_checkbox').getAttribute('data-item');
				mydata.push(id);
				return;                
			}
		});

		if(mydata.length!=1) 
		{return;}
		$.ajax({
			type: 'GET',
			url: "/positions/" + mydata[0] + "/edit",
			dataType: 'script',
			data: {
				id: mydata[0]
			},
			success: function(data, textStatus, jqXHR){
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		})
	});

	$("#btnDeletePosition").click(function() {
			var mydata = [],id;

			$.each($('.tbl_position tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.querySelector('.col_checkbox').getAttribute('data-item');
							mydata.push(id);
										 
					}   
			});

			if(mydata.length==0) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "delete_positions",
					dataType: 'script',
					data: {
									data: {ids:mydata},
					},
					success: function(data, textStatus, jqXHR){
							console.log("AJAX OK!")
					},
					error:function(jqXHR, textStatus, errorThrown){
							console.log("AJAX Error: #{textStatus}")
					}
			})
	});

	
}

function formatTblUploadDocument(id) {   
	var tbl_pdf = document.getElementsByClassName("tblUploadDocument");
	if (tbl_pdf.length > 0) {
		tbl_pdf = window.$('.tblUploadDocument').DataTable({
				'stateSave': false,
				'destroy': true,
				'fixedColumns': true,
				"lengthMenu": [10,15,20,25,50,100],
				'columnDefs': [
				{
						'targets': 0,
						'width': "4%",
						'checkboxes': {
						'selectRow': true
						}
				},
				{ "width": "4%", "targets": 1 }
			
				
				],
				'select': {
				'style': 'multi'
				},
				'order': [1, 'asc'],
				drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
		};
		$("#btnDeleteMultilDocument").click(function() {
		var id_pdf;
		var lstPdf = [];
		$.each(tbl_pdf.rows({ 'search': 'applied' }).nodes(), function (i, row) {
				var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
				if (checkBox) 
				{
				id_pdf =parseInt(row.querySelector('.col_checkbox').getAttribute('data-item'));
				lstPdf.push(id_pdf);
				return;                
				}   
		});
		if(lstPdf.length==0) return;
		$.ajax({
				type: 'GET',
				url: "confirm_delete_multi_document",
				dataType: 'script',
				data: {
				list_id_pdf: lstPdf
				},
				success: function(data, textStatus, jqXHR){
				
				},
				error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
				}
		});
	});
	 
}

function formatTableFoldeSearch(){
	// var tbl_file_folder = document.getElementsByClassName("tbl-folder-search");
  //   if (tbl_file_folder.length > 0) {
  //     tbl_file_folder = window.$('.tbl-folder-search').DataTable({
  //       'destroy': true,
  //       'columnDefs': [
  //         {
  //           'targets': 0,
  //           'width': "4%",           
  //         },
  //         { "width": "6%", "targets": 1 }
  //       ],
  //       'select': {
  //         'style': 'multi'
  //       },
  //       'order': [0, 'asc'],
  //       drawCallback: function() {
  //         $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
  //       },
  //       language:{paginate:{
  //       previous:"<i class='mdi mdi-chevron-left'>",
  //       next:"<i class='mdi mdi-chevron-right'>"}},
  //     });
	// }
}
