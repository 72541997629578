import { moveOption } from './schedule.js';
$(document).on('turbo:load', function() {
  
  // event Enter submit form
  $("body").delegate('#modal-window-equipment-new', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_add_new_equipment").click();
    }
  });

  $("body").delegate('#modal-window-equipment-edit', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_equipment").click();
    }
  });

  $("body").delegate('#modal-window-equipment-relocate', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnRelocateItems").click();
    }
  });

	// screen week schedule equipment change list category
  $('#list_category_schedule').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
		var start_date = $("#start_date").val();
    var data = e.params.data;
		var category_id = data.id;
    if (category_id == "0") {
      var lst_equipment = find_list_equipment_in_storage(document.getElementById("user_id").value);
      lst_equipment = $.param({lst_equipment:lst_equipment.split(",")});
      window.location.href = "/schedule_equipment/week_schedule?" + "category_id=" + category_id + "&" + lst_equipment + "&tab_page=" + current_page + "&start_date=" + start_date ;
    } 
    else {
      window.location.href = "/schedule_equipment/week_schedule?" + "category_id=" + category_id + "&tab_page=" + current_page + "&start_date=" + start_date ;
    }
	});

	// screen group day schedule equipment change list category
  $('#list_category_group_day').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var start_date = $("#start_date").val();
    var data = e.params.data;
    var category_id = data.id;
    if (category_id == "0") {
      var lst_equipment = find_list_equipment_in_storage(document.getElementById("user_id").value);
      lst_equipment = $.param({lst_equipment:lst_equipment.split(",")});
      window.location.href = "/schedule_equipment/group_day?" + "category_id=" + category_id +  "&" + lst_equipment + "&tab_page=" + current_page + "&start_date=" + start_date;
    } 
    else {
      window.location.href = "/schedule_equipment/group_day?" + "category_id=" + category_id + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
  });

  // screen month schedule equipment change list category
  $('#list_equipment_month_schedule').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var data = e.params.data;
    var equipment_id_month_schedule = data.id;
    var startdate = document.getElementById('dt_start_date').value;
    var cdate = document.getElementById('cdate').value;
    window.location.href = window.location.origin  + `/schedule_equipment/month_schedule?cdate=${cdate}&equipment_id=${equipment_id_month_schedule}&tab_page=${current_page}&startdate=${startdate}`;
  });

  // screen personal day schedule equipment change list category
  $('#list_equipment_personal_day').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var start_date = $("#start_date").val();
    var data = e.params.data;
    var equipment_id = data.id;
    window.location.href = "/schedule_equipment/personal_day?start_date=" + start_date + "&tab_page=" + current_page + "&" + "equipment_id=" + equipment_id ;
  });

  // button filter schedule by list equipment
  $("#btnFilterEquipment").click(function() {
    var current_page = $("#tab_page").val();
    var tab_calendar = this.value;
    var date = document.getElementById("date_schedule").value;
    var lst_equipment = find_list_equipment_in_storage(document.getElementById("user_id").value);
    lst_equipment = lst_equipment.split(",");
    $.ajax({
      type: 'GET',  
      url: "/select_list_equipment",
      dataType: 'script',
      data: { 
        start_date: date,
        tab_calendar: tab_calendar,
        lst_equipment: lst_equipment,
        tab_page: current_page
      },
      success: function(data, textStatus, jqXHR){
        
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  // modal select category filter equipment
  // $("body").delegate('#select_category_schedule_modal', 'change', function() {
  //   var options_equipment = document.getElementById("equipment_selected_schedule_modal");
  //   var lst_equipment_selected = [];
  //   for (var i=0; i<options_equipment.length; i++) {
  //     lst_equipment_selected.push(options_equipment[i].value);
  //   }
  //   $.ajax({
  //     type: 'GET',
  //     url: "/filter_list_equipment_modal",
  //     dataType: 'script',
  //     data: {
  //       category_id: $("#select_category_schedule_modal option:selected").val(),
  //       list_equipment: lst_equipment_selected
  //     },
  //     success: function(data, textStatus, jqXHR){
  //       // console.log("AJAX OK")
  //     },
  //     error:function(jqXHR, textStatus, errorThrown){
  //       console.log("AJAX Error")
  //     }
  //   })
  // });

  // move equipment screen normal schedule
  $("body").delegate('#add_equipment_modal', 'click', function() {
    var lst_equipment_select = document.getElementById('list_equipment_schedule_modal');
    var lst_equipment_selected = document.getElementById('equipment_selected_schedule_modal');
    if (lst_equipment_select.length > 0) {
      moveOption(lst_equipment_select, lst_equipment_selected);
    }
  });

  $("body").delegate('#remove_equipment_modal', 'click', function() {
    var lst_equipment_select = document.getElementById('list_equipment_schedule_modal');
    var lst_equipment_selected = document.getElementById('equipment_selected_schedule_modal');
    moveOption(lst_equipment_selected, lst_equipment_select);
  });

  $("body").delegate('#btn_filter_schedule_equipment', 'click', function (e) {
    var user_id = document.getElementById("user_id").value;
    var lst_equipment_selected = [];
    var options_equipment = document.getElementById("equipment_selected_schedule_modal");
    for (var i=0; i<options_equipment.length; i++) {
      options_equipment[i].selected = "true";
      lst_equipment_selected.push(options_equipment[i].value);
    }
    set_item_equipment_in_storage(lst_equipment_selected, user_id);
  });

// datepicker group day equipment
  $('#icon_date_group_day_equipment').click(function(){
    $("#date_group_day_equipment").datepicker().focus();
  });
  datepicker("#date_group_day_equipment");
  $("#date_group_day_equipment").on("change", function(e) { 
    var current_page = $("#tab_page").val();
    var start_date = e.target.value;
    var category_id = document.getElementById("list_category_group_day").value;
    if (category_id == "0") {
      var lst_equipment = find_list_equipment_in_storage(document.getElementById("user_id").value);
      lst_equipment = $.param({lst_equipment:lst_equipment.split(",")});
      window.location.href = "/schedule_equipment/group_day?" + "category_id=" + category_id +  "&" + lst_equipment + "&tab_page=" + current_page + "&start_date=" + start_date;
    } 
    else {
      window.location.href = "/schedule_equipment/group_day?" + "category_id=" + category_id + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
  });

// datepicker group week equipment
  $('#icon_date_week_schedule_equipment').click(function(){
    $("#date_week_schedule_equipment").datepicker().focus();
  });
  datepicker("#date_week_schedule_equipment");
  $("#date_week_schedule_equipment").on("change", function(e) { 
    var current_page = $("#tab_page").val();
    var start_date = e.target.value;
    var category_id = document.getElementById("list_category_schedule").value;
    if (category_id == "0") {
      var lst_equipment = find_list_equipment_in_storage(document.getElementById("user_id").value);
      lst_equipment = $.param({lst_equipment:lst_equipment.split(",")});
      window.location.href = "/schedule_equipment/week_schedule?" + "category_id=" + category_id + "&" + lst_equipment + "&tab_page=" + current_page + "&start_date=" + start_date ;
    } 
    else {
      window.location.href = "/schedule_equipment/week_schedule?" + "category_id=" + category_id + "&tab_page=" + current_page + "&start_date=" + start_date ;
    }
  });

// datepicker personal day equipment
  $('#icon_date_personal_day_equipment').click(function(){
    $("#date_personal_day_equipment").datepicker().focus();
  });
  datepicker("#date_personal_day_equipment");
  $("#date_personal_day_equipment").on("change", function(e) { 
    var current_page = $("#tab_page").val();
    var start_date = e.target.value;
    var equipment_id = document.getElementById("list_equipment_personal_day").value;
    window.location.href = "/schedule_equipment/personal_day?start_date=" + start_date + "&tab_page=" + current_page + "&" + "equipment_id=" + equipment_id ;
  });

});
// set item session storage filter equipment
export function set_item_equipment_in_storage(lst_equipment_selected, current_user_id) {
  var equiments = [];
  var newList = new Object();
  newList.user_id = current_user_id;
  newList.equipment_selected = lst_equipment_selected.toString();
  if (sessionStorage.getItem("listEquipmentSchedule") == '' || sessionStorage.getItem("listEquipmentSchedule") == null) {
    equiments.push(newList);
  }
  else {
    equiments = JSON.parse(sessionStorage.getItem('listEquipmentSchedule'));
    if (equiments.some(user => user.user_id == current_user_id)) {
      // users = JSON.parse(sessionStorage.getItem('listUserSchedule'));
      var objIndex = equiments.findIndex((user => user.user_id == current_user_id));
      equiments[objIndex].equipment_selected = lst_equipment_selected.toString();
      console.log(equiments);
    }
    else {
      equiments.push(newList);
    }
  }
  console.log(equiments);
  sessionStorage.setItem("listEquipmentSchedule", JSON.stringify(equiments));
}

// get item session storage filter equipment
export function find_list_equipment_in_storage(current_user_id) {
  var equiments = [];
  var retrievedObject;
  var newList = new Object();
  newList.user_id = current_user_id;
  newList.equipment_selected = "";
  if (sessionStorage.getItem("listEquipmentSchedule") == '' || sessionStorage.getItem("listEquipmentSchedule") == null) {
    equiments.push(newList);
  }
  else {
    equiments = JSON.parse(sessionStorage.getItem('listEquipmentSchedule'));
    if (equiments.some(user => user.user_id == current_user_id)) {
      equiments = JSON.parse(sessionStorage.getItem('listEquipmentSchedule'));
    }
    else {
      equiments.push(newList);
    }
  }
  sessionStorage.setItem("listEquipmentSchedule", JSON.stringify(equiments));
  retrievedObject = JSON.parse(sessionStorage.getItem('listEquipmentSchedule'));
  const lst = retrievedObject.find(u => u.user_id === current_user_id);
    // console.log(lst);
  return lst['equipment_selected']
}
// function load equipment use select2 in modal
window.filter_equipment_select2_in_modal = function(element_select2_id, equipments_selected, url, modal_id) {
  $(element_select2_id).select2({dropdownParent: $(modal_id) });

  $(element_select2_id).on('select2:select', function (e) {
    var options_equipment = document.getElementById(equipments_selected);
    var data = e.params.data;
    var category_id = data.id;
    var lst_equipment_selected = [];
    for (var i=0; i<options_equipment.length; i++) {
      lst_equipment_selected.push(options_equipment[i].value);
    }
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        category_id: category_id,
        list_equipment: lst_equipment_selected
      },
      success: function(data, textStatus, jqXHR){
        // console.log("AJAX OK")
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });
}
