import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

$(document).on('turbo:load', function() {
  if ($("#overview_chart_project").length > 0) {
    load_time_chart_project();
    load_task_chart_project();
    load_progress_chart_project();
    load_workload_chart_project();
  }

  $("body").delegate(".btn_setting_color_chart_task", "click", function (e){
    $(".popup_setting_color_chart_task").toggleClass("d-none");
  });

  if ($(".popup_setting_color").length > 0) {
    $(document).click(function(e) {
      if (!$(e.target).parents(".popup_setting_color").hasClass("popup_setting_color") && !$(e.target).parent().hasClass("btn_setting_color")) {
        $(".popup_setting_color").addClass("d-none");
      }
    });
  }

  $("body").delegate(".btn_setting_color_chart_progress", "click", function (e){
    $(".popup_setting_color_chart_progress").toggleClass("d-none");
  });

  $("body").delegate(".btn_setting_color_chart_time", "click", function (e){
    $(".popup_setting_color_chart_time").toggleClass("d-none");
  });

  $("body").delegate(".btn_setting_color_chart_workload", "click", function (e){
    $(".popup_setting_color_chart_workload").toggleClass("d-none");
  });

	$("#dashboard_project_category").delegate('.color-select .input-color', 'input', function(ev) {
    $(this).parents(".dropdown").find(".toolbarButton").css("backgroundColor", this.value);
    var name_label = $(this).parents(".dropdown").find(".toolbarButton").data('item');
    var master_plan_id = $(this).parents(".dropdown").find(".toolbarButton").data('id');
    $(this).parents(".dropdown").find(".toolbarButton").css("backgroundColor", this.value);
    $.ajax ({
      type: 'GET',
      url: "/project_manager/user_label_charts/setting_color_chart",
      dataType: 'script',
      data: {
        color: this.value,
        name_label: name_label,
        master_plan_id: master_plan_id
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

  $("#dashboard_project_category").delegate('.color-select .color-item', 'click', function(ev) {
    var color = $(this).attr("style").replace("background: ", "");
    var name_label = $(this).parents(".dropdown").find(".toolbarButton").data('item');
    var master_plan_id = $(this).parents(".dropdown").find(".toolbarButton").data('id');
    $(this).parents(".dropdown").find(".toolbarButton").css("backgroundColor", color);
    $.ajax ({
      type: 'GET',
      url: "/project_manager/user_label_charts/setting_color_chart",
      dataType: 'script',
      data: {
        color: color,
        name_label: name_label,
        master_plan_id: master_plan_id
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });
});

//time chart
window.load_time_chart_project = function() {
  let data_time = gon.data_time
  var style = getComputedStyle(document.body);
  let fontColor = style.getPropertyValue('--ct-body-color');

  const config = {
    type: 'bar',
    data: {
      labels: data_time[2],
      datasets: [{
        maxBarThickness: 20,
        minBarLength: 2,
        data: data_time[0],
        backgroundColor: data_time[1],
        borderWidth: 1
      }]
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          min: -100,
          max: 100,
          grid: {
            color: fontColor,
            lineWidth: 0.5,
          },
          ticks: {
            stepSize: 25,
            color: fontColor
          },
          border: {
            display: false,
          }
        },
        y: {
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            color: fontColor,
          }
        },
      },
      indexAxis: 'y',
      plugins: {
        legend: {
          onClick: () => {},
          labels: {
            usePointStyle: true,
            boxWidth: 8,
            boxHeight: 8,
            padding: 26,
            generateLabels: (chart) => {
              let labels = [I18n.t("project_manager.dashboards.ahead"), I18n.t("project_manager.dashboards.on_time"), I18n.t("project_manager.dashboards.behind")]
              let colors = data_time[3]
              return chart.data.labels.map((label, index) => ({
                text: labels[index],
                fontColor: colors[index],
                fillStyle: colors[index],
                strokeStyle: colors[index],
              }));
            }
          }
        },
        datalabels: {
          offset: (context) => {
            const value = context.dataset.data[context.dataIndex];
            if (value < 0) {
              return '0';
            } else if (value < 10) {
              return '-26';
            } else if (value < 100) {
              return '-33';
            } else {
              return '-40';
            }
          },
          anchor: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return value > 0 ? 'start' : 'end';
          },
          align: 'end',
          color: fontColor,
          formatter: (value) => {
            return value === null ? '0%' : `${value}%`;
          },
        }
      }
    },
    plugins: [ChartDataLabels],
  };

  window.timeChartProject = new Chart($("#chart_time_project"), config);
  window.timeChartProjectModal = new Chart($("#chart_time_project_modal"), config);
}

//task chart
window.load_task_chart_project = function() {
  let data_task = gon.data_task
  const data = {
    labels: [I18n.t("project_manager.dashboards.not_started"), I18n.t("project_manager.dashboards.complete"), I18n.t("project_manager.dashboards.in_progress")],
    datasets: [{
      data: data_task[0],
      backgroundColor: data_task[1],
      borderColor: data_task[1],
      borderWidth: 1,
      cutout: "75%",
    }]
  };

  const customDatalabels = {
    id: 'customDatalabels',
    afterDatasetsDraw(chart, args, pluginOptions){
      const { ctx, data, chartArea: { top, bottom, left, right, width, height }} = chart;

      ctx.save();
      const haftWidth = width / 2 + left;
      const haftHeight = height / 2 + top;

      data.datasets[0].data.forEach((datapoint, index) => {
        if (datapoint > 0) {
          const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition()
          ctx.font = "bold 14px sans-serif";
          ctx.fillStyle = data.datasets[0].borderColor[index];
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const xLine = x >= haftWidth ? x + 15 : x - 15;
          const yLine = y >= haftHeight ? y + 25 : y - 25;
          const extraLine = x >= haftWidth ? 10 : -10;
          const textWidth = x >= haftWidth ? ctx.measureText(datapoint).width : -ctx.measureText(datapoint).width;
          ctx.strokeStyle = data.datasets[0].borderColor[index];
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.stroke();
          ctx.fillText(datapoint, xLine + extraLine + textWidth, yLine);
        }
      })
      ctx.restore();
    }
  }

  // config
  const config = {
    type: 'doughnut',
    data,
    options: {
      layout: {
        padding: {
          top: 6,
        },
      },
      radius: "80%",
      aspectRatio: 1,
      plugins: {
        legend: {
          labels: {                
            usePointStyle: true,
            boxWidth: 8,
            boxHeight: 8,
            padding: 18,
            generateLabels: (chart) => {
              var labels = chart.data.labels;
              var colors = chart.data.datasets[0].backgroundColor
              return chart.data.labels.map((label, index) => ({
                text: labels[index],
                fontColor: colors[index],
                fillStyle: colors[index],
                strokeStyle: colors[index],
              }));
            } 
          }
        }
      }
    },
    plugins: [customDatalabels]
  };

  window.taskChartProject = new Chart($("#chart_task_project"), config);
  window.taskChartProjectModal = new Chart($("#chart_task_project_modal"), config);
}

//progress chart
window.load_progress_chart_project = function() {
  var style = getComputedStyle(document.body);
  let fontColor = style.getPropertyValue('--ct-body-color');
  let data_progress = gon.data_progress
  const config = {
    type: 'bar',
    data: {
      labels: truncateLabels(data_progress[0], 10),
      datasets: [{
        maxBarThickness: 18,
        data: data_progress[1],
        backgroundColor: data_progress[2],
        setPercentage: [10, 10]
      }],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 12,
          top: 12,
        },
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          offset: (context) => {
            const value = context.dataset.data[context.dataIndex];
            if (value < 0) {
              return '0';
            } else if (value < 10) {
              return '-26';
            } else if (value < 100) {
              return '-33';
            } else {
              return '-40';
            }
          },
          anchor: 'start',
          align: 'end',
          color: fontColor,
          formatter: (value) => {
            return value === null ? '0%' : `${value}%`;
          },
        }
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false
          },
          gridLines: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks:{
            color: fontColor
          }
        },
        x: {
          max: 100,
          min: -15,
          display: false,
        }
      },
      indexAxis: 'y',
    },
    plugins: [ChartDataLabels],
  };

  window.progressChartProject = new Chart($("#chart_progress_project"), config);
  window.progressChartProjectModal = new Chart($("#chart_progress_project_modal"), config);

  if (config.data.labels.length > 7) {
    let newHeight = 320 + ((config.data.labels.length - 7) * 20);
    $('.height-chart-progress-project')[0].style.height = `${newHeight}px`;

    let newHeightModal = 320 + ((config.data.labels.length - 7) * 30);
    $('.height-chart-progress-project-modal')[0].style.height = `${newHeightModal}px`;
  }
}

//workload chart
window.load_workload_chart_project = function() {
  var style = getComputedStyle(document.body);
  let fontColor = style.getPropertyValue('--ct-body-color');
  let data_workload = gon.data_workload
  const config = {
    type: 'bar',
    data: {
      labels: truncateLabels(data_workload[0], 10),
      datasets: [{
        label: I18n.t("project_manager.dashboards.completed"),
        maxBarThickness: 18,
        data: data_workload[1][0],
        backgroundColor: data_workload[2][0],
      },
      {
        label: I18n.t("project_manager.dashboards.remaining"),
        maxBarThickness: 18,
        data: data_workload[1][1],
        backgroundColor: data_workload[2][1],
      },
      {
        label: I18n.t("project_manager.dashboards.overdue"),
        maxBarThickness: 18,
        data: data_workload[1][2],
        backgroundColor: data_workload[2][2],
      }
    ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 12,
        },
      },
      barPercentage: 0.4,
      categoryPercentage: 1,
      radius: 100,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        }
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false
          },
          gridLines: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks:{
            color: fontColor
          }
        },
        x: {
          stacked: true,
          min: 0,
          grid: {
            color: fontColor,
            lineWidth: 0.5,
          },
          border: {
            color: fontColor,
            width: 0.5,
          },
          ticks:{
            display: false,
            stepSize: 4
          }
        }
      },
      indexAxis: 'y',
    },
    plugins: [ChartDataLabels],
  };

  const configLabel = {
    type: 'bar',
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 12,
        },
      },
      barPercentage: 0.4,
      categoryPercentage: 1,
      radius: 100,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          position: 'bottom',
          maxHeight: 50,
          labels: {
            usePointStyle: true,
            boxWidth: 8,
            boxHeight: 8,
            padding: 24,
            generateLabels: (chart) => {
              let labels = [I18n.t("project_manager.dashboards.completed"), I18n.t("project_manager.dashboards.remaining"), I18n.t("project_manager.dashboards.overdue")];
              let colors = data_workload[2];
              return labels.map((label, index) => ({
                text: labels[index],
                fontColor: colors[index],
                fillStyle: colors[index],
                strokeStyle: colors[index],
              }));
            } 
          },
          title: {
            padding: 10,
          }
        }
      },
      indexAxis: 'y',
    },
    plugins: [ChartDataLabels],
  };

  const configScaleY = {
    type: 'bar',
    data: {
      labels: data_workload[0].map(() => ""),
      datasets: [{
        maxBarThickness: 18,
        data: data_workload[1][0],
        backgroundColor: data_workload[2][0],
      },
      {
        maxBarThickness: 18,
        data: data_workload[1][1],
        backgroundColor: data_workload[2][1],
      },
      {
        maxBarThickness: 18,
        data: data_workload[1][2],
        backgroundColor: data_workload[2][2],
      }
    ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 12,
        },
      },
      barPercentage: 0.4,
      categoryPercentage: 1,
      radius: 100,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        }
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false
          },
          gridLines: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks:{
            color: fontColor
          },
          afterFit: ((context) =>{
            context.width += workloadChartProject.chartArea.left - 12
          }),
        },
        x: {
          stacked: true,
          min: 0,
          afterFit: ((context) =>{
            context.height += 30
          }),
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false
          },
          border: {
            display: false,
          },
          ticks:{
            color: fontColor,
            stepSize: 4
          }
        }
      },
      indexAxis: 'y',
    },
    plugins: [ChartDataLabels],
  };


  window.workloadChartProject = new Chart($("#chart_workload_project"), config);
  window.workloadChartProjectModal = new Chart($("#chart_workload_project_modal"), config);
  window.workloadChartProjectLabel = new Chart($("#chart_workload_label"), configLabel);
  window.workloadChartProjectLabelModal = new Chart($("#chart_workload_label_modal"), configLabel);
  window.workloadChartProjectScaleY = new Chart($("#chart_workload-scaleY"), configScaleY);
  window.workloadChartProjectScaleYModal = new Chart($("#chart_workload-scaleY-modal"), configScaleY);

  if (config.data.labels.length > 7) {
    let newHeight = 320 + ((config.data.labels.length - 7) * 20);
    $('.height-chart-workload-project')[0].style.height = `${newHeight}px`;

    let newHeightModal = 320 + ((config.data.labels.length - 7) * 30);
    $('.height-chart-workload-project-modal')[0].style.height = `${newHeightModal}px`;
  }
}

function truncateLabels(labels, maxLength) {
  return labels.map(label => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + '...';
    }
    return label;
  });
}
