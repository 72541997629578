import "quill-mention";
import Quill from "quill";

window.initialQuill = function (elemnt, input) {
  if ($(elemnt).length > 0){
    let master_plan_id = $(elemnt).data("master-plan-id")
    var quillEditor = new Quill(elemnt, {
      theme: "snow",
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["link", "file"]
          ],
          handlers: {
            // image: quill_img_handler,
          },
        },
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          source: function (searchTerm, renderList, mentionChar) {
            let content_comment = document.querySelector(".ql-editor").innerHTML;
            let doc = new DOMParser().parseFromString(content_comment, "text/html");
            let list_mention_user = doc.getElementsByClassName("mention");
            let arr_mention_user = [];

            if (list_mention_user.length > 0) {
              $.each(list_mention_user, function (index, elem) {
                arr_mention_user.push($(elem).data("id"));
              });
            }
            $.ajax({
              url: "/project_manager/filter_member/" + master_plan_id,
              type: "get",
              data: {
                q: searchTerm,
                without_user: arr_mention_user,
              },
              async: true,
            }).done(function (values) {
              renderList(values, searchTerm);
            });
          },
        },
      },
    });
    if ($("#input_comment_issue_box").length > 0) {
      quillEditor.keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, context) => {
          $("#btn_submit_comment").click();
        }
      });
    }

    quillEditor.root.innerHTML = $(input).val();

    quillEditor.focus();
    quillEditor.setSelection($(input).val().length, 0);
    
    quillEditor.on("text-change", function () {
      $(input).val(quillEditor.root.innerHTML);
    });
  }
}
